import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';
import { ScrollToService } from 'ng2-scroll-to-el';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css'],
  
})
export class FeaturesComponent implements OnInit {



  public carouselOptions: NguCarousel;
  public services = [{
    icon: 'place',
    text: `Log on to tag8 Webpanel & view real time tracking and Location Logs of last 30 days.`,
    title: 'Track Your Phone'
  }, {
    icon: 'mail',
    text: `Receive Email alerts for last seen location of the phone and Retry Email provision.`,
    title: 'Email Alerts'
  }, {
    icon: 'phonelink_ring',
    text: `Get a web panel to trigger lost mode, monitor lost phone remotely and ring your phone.`,
    title: 'Ring Your Device'
  }, {
    icon: 'sim_card',
    text: `Detect SIM card change and send email alerts with the details of new SIM changes.`,
    title: 'Sim Change Alert'
  }, {
    icon: 'person_add_disabled',
    text: `Capture Image & Location of Unauthorized users using Machine Learning.`,
    title: 'Intruder Image'
  }, {
    icon: 'card_giftcard',
    text: `Finder receives reward from tag8 and optional reward from owner for returning the phone.`,
    title: 'Finder Reward'
  },{
    icon: 'directions_bus',
    text: `Phone is recovered from the finder and is returned to the owner at his DoorStep.`,
    title: 'Door Step Delivery'
  },{
    icon: 'headset_mic',
    text: `Simply call our 24 x 7 Customer support to get assitance for tracking your lost phone.`,
    title: 'Customer Support'
  }
]

  
constructor(private scrollService: ScrollToService,
  public el: ElementRef) { }





  ngOnInit() {
    this.carouselOptions = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
      slide: 2,
      speed: 400,
      interval: 2000,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true
    }

  }

  scrollToAI(element) {
    this.scrollService.scrollTo(element, 700, -60);
  }

  scrollTopic(element) {
    this.scrollService.scrollTo(element, 700, -85);
  }
  

}
