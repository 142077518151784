import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth.service'
import { UserService } from '../../../core/user.service';
import { HttpClient } from '@angular/common/http';
import { Router, Params } from '@angular/router';
import { Chart } from 'chart.js';
import * as moment from 'moment';
@Component({
  selector: 'app-forms',
  templateUrl: './UserProfile.component.html',
  styleUrls: ['./UserProfile.component.scss']
})
export class UAUserProfileComponent implements OnInit {
  chart: any;
  userid: any;
  username: any;
  userAssets: any;
  totalDays: any;
  leftDays: any;
  startDate: any;
  endDate: any;
  pending:any;
  user_status:any;
  emergency_phone:any;
  savednetworks = [];
  wifiData = [];
  constructor(
    public authService: AuthService,
    public userService: UserService,
    private router: Router,
    public http: HttpClient,
  ) { }

  ngOnInit() {

    
        this.userid = sessionStorage.getItem('viewUser');
        console.log(this.userid);
        this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
          headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
        }).subscribe((re: any) => {
          this.userAssets = re.data;
          this.username = this.userAssets[0].name;  
          

          this.http.get('https://digitaltag.tag8.in/api/getwifiData/' + this.userid + '&' + this.userAssets[0].imei, {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
          }).subscribe((res: any) => {
            this.wifiData = res.data;
          });


          

        


});



      
}



getProfileView(usersStatus,startDate,endDate) {

    if(usersStatus == 'FREE') {

      this.user_status = 'FREE';

      console.log('FREE USER');

     } else if (usersStatus = 'PAID') {

      this.user_status = 'PAID';
      console.log('PAID USER');
          console.log(startDate);
          console.log(endDate);
          this.startDate = moment(startDate);
          this.endDate = moment(endDate);
          this.totalDays = this.endDate.diff(this.startDate, 'days');
          let currentDate = moment();
          this.leftDays = this.endDate.diff(currentDate, 'days');
           this.pending = parseInt(this.totalDays) - parseInt(this.leftDays);
          setTimeout( ()=> {
             this.generateChart(this.leftDays,this.pending);
          },1000);
     } 


}


generateChart(days1,days2) {
  this.chart = new Chart('canvas', {
    type: 'doughnut',
    data: {
      labels: ['Days Left', 'Days Completed'],
      datasets: [
        { 
          data: [parseInt(days1), parseInt(days2)],
          backgroundColor: ["#fb8c00","#1f1f1f"],
          fill: false,
          borderWidth: 1, 
        },
      ]
    },
    options: {
      legend: {          
        position:'bottom',
        labels: {
          fontColor: "#000000"
        }
      },
      cutoutPercentage:65,
      responsive: true,
      tooltips:{
        enabled:true,
        backgroundColor:'#000000',
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      
    }
  });
}

  
tabClick(tab) {
  // for(let i=0; i<this.userAssets.length; i++)
  // {

    console.log(tab.index);
    this.emergency_phone = this.userAssets[tab.index].emergency_phone;

   // if (tab.index === i ) {

      this.getProfileView(this.userAssets[tab.index].subscription_plan,this.userAssets[tab.index].membership_date ,this.userAssets[tab.index].membership_expiry_date);   
   // } else {

    //  console.log(this.userAssets[i].model);   

    //  this.getProfileView(this.userAssets[i].subscription_plan,this.userAssets[i].membership_date ,this.userAssets[i].membership_expiry_date);
   // }


     

  //}
}

}

  

