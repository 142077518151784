import { Component, OnInit, ViewChild, } from '@angular/core';
import { AuthService } from '../../../core/auth.service';
import { UserService } from '../../../core/user.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { delay } from 'rxjs/internal/operators';
import { MatSnackBar } from '@angular/material';
import { element } from 'protractor';
import { getLeadingCommentRanges } from 'typescript';
declare var google: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class UAMapsComponent implements OnInit {



  zoom: number = 15;

  // initial center position for the map
  lat;
  lng;

  uplat;
  uplng;
  update;
  showmap: true;
  userid: any;
  startDate: any;
  endDate: any;
  origin: any;
  destination: any;
  waypts = [];
  odpts = [];

  ldatas = [];
  movingdata = [];
  index = 0;


  map: any;
  line: any;
  userAssets: any;
  directionsService: any;
  userdata: any;
  device_id;

  icon = {
    url: './assets/map icongreen.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  }
 

  public stringArr: Array<any> = []


  constructor(
    public snackBar: MatSnackBar,
    public authService: AuthService,
    public userService: UserService,
    public http: HttpClient,
    
  ) { 
    
   }






  onMapReady(map: any) {
    console.log(map);
    console.log(sessionStorage.getItem('uid'));
    this.map = map;
    this.http.get('https://digitaltag.tag8.in/api/getDetails/' + sessionStorage.getItem('viewUser'), {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }

    ).subscribe((re: any) => {
      console.log(re.data[0]);
      this.userAssets = re.data;
      this.userdata = re.data[0];
      this.lat = parseFloat(re.data[0].latitude);
      this.lng = parseFloat(re.data[0].longitude);

      this.map.setCenter({ lat: this.lat, lng: this.lng });
      this.map.fitBounds({ lat: this.lat, lng: this.lng });

    });
  }


  ngOnInit() {


    this.userid = sessionStorage.getItem('viewUser');
    console.log(this.userid);




  }


  updateMarker(ldatas) {

    

    this.ldatas = ldatas;
   this.uplat = ldatas[this.index].latitude;
   this.uplng =  ldatas[this.index].longitude;
   

   

  if(this.index < ldatas.length -1) {
   
   

   setTimeout(() =>{
     
    this.updateMarker(ldatas);
    this.index = this.index + 1;
    
    this.uplat = '';
   this.uplng =  '';
   },6000)
  }
   

  }



  getLocations() {
    console.log(this.device_id);
    const loc = this.http.get('https://digitaltag.tag8.in/api/getlocationbydeviceid/' + this.device_id
      , {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    loc.subscribe((response: any) => {
      this.snackBar.open('Fetching Location Data', 'Undo', {
        duration: 3000
      });

      this.ldatas = response.data;
      var arr = [];
      arr = this.ldatas;
      var newArray = [];
      for (let i = 0; i < arr.length; i++) {
        if (i > 0) {
          if (arr[i].latitude === arr[i - 1].latitude) {
            console.log('Remove Duplicated')
          } else {
            // console.log(arr[i].address);
            var data = {
              latitude: parseFloat(arr[i].latitude),
              longitude: parseFloat(arr[i].longitude),
              modifiedOn: new Date(arr[i].modifiedOn),
              address: arr[i].address
            }
            newArray.push(data);
          }
        }
      }
      this.ldatas = [];
      newArray.sort((val1: any, val2: any) => { return +new Date(val2.modifiedOn) - +new Date(val1.modifiedOn) })
      console.log(newArray);
      this.ldatas = newArray;
  
    });
  }


  

 




  


  getDates() {
    const start = this.startDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '00:30');
    const fin = this.endDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '23:00');

    console.log(start);
    console.log(fin);


    const loc = this.http.get('https://digitaltag.tag8.in/api/getlocationbydate/' + sessionStorage.getItem('viewUser')
      + '&' + start + '&' + fin, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    loc.subscribe((response: any) => {
      this.ldatas = response.data;
      var arr = [];
      arr = this.ldatas;
      var newArray = [];
      for (let i = 0; i < arr.length; i++) {
        if (i > 0) {
          if (arr[i].latitude === arr[i - 1].latitude) {
            console.log('Remove Duplicated')
          } else {
            // console.log(arr[i].address);
            var data = {
              latitude: parseFloat(arr[i].latitude),
              longitude: parseFloat(arr[i].longitude),
              modifiedOn: new Date(arr[i].modifiedOn),
              address: arr[i].address
            }
            newArray.push(data);
          }
        }
      }

      this.ldatas = [];

      newArray.sort((val1: any, val2: any) => { return +new Date(val2.modifiedOn) - +new Date(val1.modifiedOn) })
      console.log(newArray);
      this.ldatas = newArray;
      // let latlng = new google.maps.LatLng(this.ldatas[0].latitude, this.ldatas[0].longitude);
      //         let request = {
      //           latLng: latlng
      //         };   
      // let geocoder = new google.maps.Geocoder();
      // geocoder.geocode(request, (results, status) => {
      //   if (status == google.maps.GeocoderStatus.OK) {
      //     if (results[0] != null) {
      //       let city = results[0].address_components[results[0]
      //                 .address_components.length-4].short_name;
      //       console.log(city);
      //     } else {
      //       alert("No address available");
      //     }
      //   }
      // })

    });
  }


  

  sortNumber(a, b) {
    return a - b;
  }


}
