import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../core/auth.service';


@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class CcMapsComponent implements OnInit {
  lat: number = 19.0178254;
  lng: number = 72.8284586;
  places;
  icon = {
    url: './assets/map icon red.png',
    scaledSize: {
      width: 20,
      height: 20
    }
  };
  icon2 = {
    url: './assets/map icongreen.png',
    scaledSize: {
      width: 20,
      height: 20
    }
  };
  constructor(public http: HttpClient, public authService: AuthService) { }

  ngOnInit() {
    const loc = this.http.get('https://digitaltag.tag8.in/api/locationdetails', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    loc.subscribe((response: any) => {
      this.places = response.data;
      console.log(response);

    });

  }

}
