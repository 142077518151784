import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
declare var google: any;
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  
    // google maps zoom level
    zoom: number =  15;

    color: 'red';
    weight: number = 3;
    icon2 = {
      url: './assets/map icongreen.png',
      scaledSize: {
        width: 30,
        height: 30
      }
    };
    polylines: any;
    lat1;
    lng1;
    userid: any;
    username: any;
    userAssets: any;

    checked = true;

    position = 'above';
    page = 'Home';
    ic = 'home';
    map: any;
    bounds;
    activate = 'block' ;
    deactivate = 'none' ;
    ring = 'Ring Device';
    lost = 'Lost Mode';
    


    show = 'none';
    hide = 'block';
    lat;
    lng;



  constructor(
    
    // private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public  http: HttpClient,
   
  ) {
    

 

  }
  







  ngOnInit() {

    this.userid = this.route.snapshot.paramMap.get('id');


    this.lat = parseFloat('19.102512');
    this.lng = parseFloat('72.8320677');




    }

    onMapReady(map: any) {
      console.log(map);
      
      this.map = map;
      const  uid = 'LR9i8YhxxqQJYqhXmLmN0pmnTzI3';
      this.http.get('https://digitaltag.tag8.in/api/getdtls/' + this.userid ).subscribe( (re: any) => {
        console.log(re.data[0]);

      this.userAssets =  re.data;

        const lt = parseFloat(re.data[0].latitude);
        const lg = parseFloat(re.data[0].longitude);

        // var bounds = new google.maps.LatLng({lat:parseFloat(re.data[0].latitude), lng:parseFloat(re.data[0].longitude)});
        // console.log(bounds);
        this.map.setCenter({ lat: lt , lng: lg });
        this.map.fitBounds({ lat: lt , lng: lg });

        
        

                     });
    }


}
