import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';


import { FooterComponent } from '../main-layout/footer/footer.component';
import { MapComponent } from './maps/map.component';
import { FreeDashboardComponent } from './dashboard/dashboard.component';


//Material Module
//Material Module start
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { UpgradepremiumComponent } from './upgradepremium/upgradepremium.component';




@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,


    /* SharedModule, */
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: ''
    })
  ],
  declarations: [
    FooterComponent,
    MapComponent,
    FreeDashboardComponent,
    UpgradepremiumComponent,

  ],
  exports: [
    FooterComponent,
    MapComponent, 
    FreeDashboardComponent,
    UpgradepremiumComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }
