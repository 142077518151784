import { Component, OnInit , ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../core/auth.service'
import { UserService } from '../../../core/user.service';
import { MatSnackBar } from '@angular/material';
import { LightboxConfig, Lightbox } from 'ngx-lightbox';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';



export interface DialogData {
  fcm_token:any,
  mdl:any,
  imi:any
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class FreeDashboardComponent implements OnInit {




//To Pass ImageURL ,Name and email
  userData;
  //Variables to display message on ringing 
  ring = 'Ring Device';
  lost = 'Lost Mode';
  //variables for hiding and showing
  show = 'none';
  hide = 'block';
  activate = 'block';
  deactivate = 'none';
  userid: any;
  deviceid: any;
  userAssets: any;

  message = 'Test';

  //to display Logs 
  imagesLength;
  images;
  imageData;
  _album = [];
  _albums = [];

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private _lightbox: Lightbox,
    public dialog: MatDialog,
    
  ) { }


  getfcmToken(fcmtoken) {
    this.ring = 'Ringing';
    const flag = 'Device Located';
    console.log(fcmtoken);
    const body = { token: fcmtoken, title: flag };
    this.http.post('https://digitaltag.tag8.in/api/pushnotification/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Ringing', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.hide = 'none';
    this.show = 'block';

  }

  ringOff(fcmtoken) {
    this.show = 'none';
    this.ring = 'Ring Device';
    const flag = 'Stop Ringing';
    const body = { token: fcmtoken, title: flag };
    this.http.post('https://digitaltag.tag8.in/api/pushnotification/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Stoppped Ringing', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.show = 'none';
    this.hide = 'block';
  }

  lostmode(fcmtoken, imei) {

    this.lost = 'Lost Mode On';
    console.log(imei);
    const msg = 'Lost Mode Activated';
    const msgbody = 'Lost Mode has been Activated. This Phone is being Tracked';
    const st = 'Device was reported as Lost';
    const body = { imei: imei, token: fcmtoken, title: msg, subtitle: msgbody, status: st };
    this.http.post('https://digitaltag.tag8.in/api/lostmodeactivate/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Lost Mode Activated', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.activate = 'none';
    this.deactivate = 'block';

  }

  securemode(fcmtoken, imei) {

    this.lost = 'Lost Mode';
    const msg = 'Lost Mode Deactivated';
    const msgbody = 'Lost Mode has been Deactivated. Your Phone is Secure';
    const st = 'SECURE';
    const body = { imei: imei, token: fcmtoken, title: msg, subtitle: msgbody, status: st };
    this.http.post('https://digitaltag.tag8.in/api/lostmodeactivate/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Lost Mode  Deactivated', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.activate = 'block';
    this.deactivate = 'none';



  }



  lockdevice(fcmtoken , imei ) {


    console.log('Lock Device');
    const msg = 'Device Locked';
    const msgbody = 'Locking Device';
  
    const body =  { imei: imei  , token: fcmtoken , title: msg  };
    this.http.post('https://digitaltag.tag8.in/api/lockdevice' , body , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken')}
    }).subscribe((response) => {
      this.snackBar.open('Device Locked', 'Undo', {
        duration: 3000
      });
   console.log(response);
    });
  
  
   }
  


 


  tabClick(tab) {
    for(let i=0; i<this.userAssets.length; i++)
    {

      if (tab.index === i  ) {
        
            var dt = localStorage.getItem('model');
            if(typeof(dt) === undefined) {
              
              localStorage.setItem("model", this.userAssets[i].model);
              localStorage.setItem("imei", this.userAssets[i].imei);
              

              
              
              let imei = localStorage.getItem('imei'); 
              this.getActivityLogs(this.userid,this.deviceid,imei);
              

              break;
            
              
            }  else  {
              localStorage.removeItem("model");
              localStorage.removeItem("imei");
              localStorage.setItem("model", this.userAssets[i].model);
              localStorage.setItem("imei", this.userAssets[i].imei);
              var dt1 = localStorage.getItem('model');
              
              let imei = localStorage.getItem('imei'); 
              this.getActivityLogs(this.userid,this.deviceid,imei);
              break;
              
            }
          
      }
    }
    
  }

  logout() {
    this.authService.doLogout()
      .then((res) => {

        this.router.navigate(['/login']);
      }, (error) => {
        console.log('Logout error', error);
      });
  }



  getActivityLogs(uid , deviceid, imei) {
    //TODO: Write the implemetation of the function
    this._albums = [];
    
    // const len = this.userAssets.length;
    // console.log(len);
    // for (let i = 0; i < len; i++) {
      
      this.http.get('https://digitaltag.tag8.in/api/activitiesimage/' + uid + '&' + deviceid, { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') } }).subscribe((resp: any) => {

      console.log(resp);
      
      const le = this.imagesLength;
    
      for (let k = 0; k < le; k++) {
          for (let j = 0; j < resp.data.length; j++) {
            
           
            const st1 = resp.data[j].status;
            const tmp = resp.data[j].timestamp;
            const im = resp.data[j].imei;
            const str = this.imageData[k].key;
            const getimei = str.indexOf('_') + 1;
            const getimeival = str.indexOf('_', getimei);
            const imeival = str.substring(getimei, getimeival);
            const start_pos = str.indexOf('_') + 1;
            const end_pos = str.indexOf('_', start_pos);
            const result1 = str.substring(start_pos);
            const timest = result1.lastIndexOf('_');
            const time = result1.substr(timest + 1);
            const st = result1.indexOf('_') + 1;
            const ep = result1.indexOf('_', st);
            const status = result1.substring(st, ep);
           
              
            if (tmp === time && st1 === status && imeival == imei) {
              console.log(imeival);
              const src = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + this.imageData[k].key;
              const thumb = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + this.imageData[k].key;
              const album = {
                src: src,
                thumb: thumb,
                time: tmp,
                status: status,
                imei: im,
                
                latitude: resp.data[j].latitude,
                longitude: resp.data[j].longitude

              };
           
              this._albums.push(album);
              
              this._albums.sort((val1: any, val2: any) => { return +new Date(val2.time) - +new Date(val1.time) })
              
              
              break;
            }
          }
        }
      });
    //}


  }





  


  ngOnInit() {
    try{

      this.userService.getCurrentUser().then((res) => {
        // const email = res.providerData[0].email;
        const email = res.email;
         this.userData = {
          // email: res.providerData[0].email,
          photo: res.photoURL,
          name:res.displayName
        }

        // console.log("ress",res);
        // console.log("Emailll",email);
  
        this.http.get('https://digitaltag.tag8.in/api/getUsersByEmail/' + email, {
          headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
        }).subscribe((response: any) => {
          this.userid = response.data.uid;
          console.log(this.userid);
          this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
          }).subscribe((re: any) => {
            this.userAssets = re.data;
            this.imagesLength =  re.image.length;
            this.imageData = re.image;
          
            //Code for the Activity Logs
            
            // const len = Object.keys(re.data).length;
            // console.log(len);
            // for (let i = 0; i < len; i++) {
            //   const imei = re.data[i].imei;
            //   const uid = re.data[i].uid;
            //   this.http.get('https://digitaltag.tag8.in/api/activitylogsimage/' + uid + '&' + imei, { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') } }).subscribe((resp: any) => {
            //     console.log(resp);
            //   const le = re.image.length;
            //     for (let k = 0; k < le; k++) {
            //       for (let j = 0; j < resp.data.length; j++) {
            //         const st1 = resp.data[j].status;
            //         const tmp = resp.data[j].timestamp;
            //         const im = resp.data[j].imei;
            //         const str = re.image[k].key;
            //         const getimei = str.indexOf('_') + 1;
            //         const getimeival = str.indexOf('_', getimei);
            //         const imeival = str.substring(getimei, getimeival);
            //         const start_pos = str.indexOf('_') + 1;
            //         const end_pos = str.indexOf('_', start_pos);
            //         const result1 = str.substring(start_pos);
            //         const timest = result1.lastIndexOf('_');
            //         const time = result1.substr(timest + 1);
            //         const st = result1.indexOf('_') + 1;
            //         const ep = result1.indexOf('_', st);
            //         const status = result1.substring(st, ep);
            //         if (tmp === time && st1 === status) {
            //           const src = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + re.image[k].key;
            //           const thumb = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + re.image[k].key;
            //           const album = {
            //             src: src,
            //             thumb: thumb,
            //             time: tmp,
            //             status: status,
            //             imei: im,
            //             latitude: resp.data[j].latitude,
            //             longitude: resp.data[j].longitude
  
            //           };
            //           this._albums.push(album);
  
            //           this._albums.sort((val1: any, val2: any) => { return +new Date(val2.time) - +new Date(val1.time) })
  
            //           console.log(this._albums);
            //           break;
            //         }
            //       }
            //     }
            //   });
            // }
            //End of the Activity Logs Image
          });
        });
  
      });
  
    }

    
    catch(err)
    {
      console.log(err);
    }

    

    

  

}
}


