import { Component, OnInit } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  public carouselOptions: NguCarousel;
  public testimonials = [{
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mBDlqyI50gWY0iCdjfmGlnya7UYLHu3k34Vw_ovXQ=w48-h48-n-rw',
    text: `“Awesome lost phone tracking app. Does not intrude into privacy and ensure it tracks the phone for intruders. Sends alert for any snooping or break in attempts. Much better than insurance. Helps to file police complaint with evidence. ”`,
    title: 'Mahesh Sheth'
  }, {
    photo: 'https://lh3.googleusercontent.com/-r_f2Z0IPMIw/AAAAAAAAAAI/AAAAAAAAAAA/ACevoQPU9Iy-KuWIAvNfxaPX2_Thsz-rKw/w48-h48-n-rw-mo/photo.jpg',
    text: `“great app for Anti theft and locating smartphones. the premium version provides a complete security for ones phone. would definitely recommend this app. ”`,
    title: 'Rohan Jain'
  }, {
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mBbVPIswV9bWsyRB9FsAD8BeRzI7nxvtNRLrhnmfQ=w48-h48-n-rw',
    text: `“They have came up with a very good idea!! Now no fear of losing your phone!!! travel anywhere and everywhere without the fear of losing your phone!!! Very useful app and awsome!!!!”`,
    title: 'Fenny Shah'
  }, {
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mD_FtCmgr4cE1H339pw60FUW5-WYIuUFzlxWztL=w48-h48-n-rw',
    text: `“Something better compared to what we had before. The best app to recover your lost or stolen phone according to me. ”`,
    title: 'Rishabh Mishra'
  }, {
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mD-VRd_76xAYSlE25jPNeE0kOjWHO1QxSSKqyUl=w48-h48-n-rw',
    text: `“grear app for aniti theft and locating smart phones the primeum version is highly recommended. ”`,
    title: 'Dimple Bhatt'
  }, {
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mBcT-Fp8bCFnswJUUEhTFfiKsIE_fB4RGZjS_S0SA=w48-h48-n-rw',
    text: `“An amazing app to track your lost or stolen phone. ”`,
    title: 'Rohan Sohonee'
  }, {
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mD2rNZeJeUrE3QpWVgSrumJJi2DgHwq5QzojwDJ=w48-h48-n-rw',
    text: `“unique app for mobiles. really helps track mobiles and activities. great interface, the premium version is definitely worth it. ”`,
    title: 'Divit Shetty'
  }, {
    photo: 'https://lh3.googleusercontent.com/a-/AAuE7mA33ryUvObtTvHVTQSQNNylwIXdX-QYwbaczYlrmA=w48-h48-n-rw',
    text: `“great app...to get the phone back when it get lost...gr8 security features..!!! ”`,
    title: 'Chandan Gupta'
  }]
  constructor() { }

  ngOnInit() {
    this.carouselOptions = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
      slide: 2,
      speed: 400,
      interval: 4000,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true
    }
  }

}
