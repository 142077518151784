import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  
  public map;

  title: string = 'Last Know Location';
  lat: number;
  lng: number;
  userid;
  userAssets;
  
  constructor(
    public userService: UserService,
    public http: HttpClient) { }


    onMapReady(map: any) {
      console.log(map);
      console.log(sessionStorage.getItem('uid'));
      this.map = map;
      this.http.get('https://digitaltag.tag8.in/api/getDetails/' + sessionStorage.getItem('uid'), {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
      }
  
      ).subscribe((re: any) => {
  
  
        const lt = parseFloat(re.data[0].latitude);
        const lg = parseFloat(re.data[0].longitude);
  

        //console.log(lt);
        this.map.setCenter({ lat: lt, lng: lg });
        this.map.fitBounds({ lat: lt, lng: lg });
  
      });
    }
  


  ngOnInit() {

    this.userService.getCurrentUser().then((res) => {
      console.log(res);
      const email = res.providerData[0].email;
      this.http.get('https://digitaltag.tag8.in/api/getUsersByEmail/' + email, {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
      }).subscribe((response: any) => {
        this.userid = response.data.uid;
        console.log(this.userid);
        this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
          headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
        }).subscribe((re: any) => {
          this.userAssets = re.data;
        });
      });
    });

  }

}
