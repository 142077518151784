import { Component, OnInit , ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import { AuthService } from '../core/auth.service';
import { Location } from '@angular/common';
import { Router, Params } from '@angular/router';
@Component({
  selector: 'app-addnotifications',
  templateUrl: './addnotifications.component.html',
  styleUrls: ['./addnotifications.component.css']
})
export class AddnotificationsComponent implements OnInit {

   title: string;
   subtitle: string;
   topic: string;
   singleFile: File;
   url:string;
   fileToUpload: File = null;

   public imagePath;
   imgURL: any;
   public message: string;

   @ViewChild('myInput')
    myInputVariable: any;

  constructor(public  http: HttpClient,
     public snackBar: MatSnackBar,
     private router: Router,
     public authService: AuthService,
     private location: Location) { }

  ngOnInit() {
  }

  logout() {
    sessionStorage.removeItem('token');
    this.router.navigate(['/admin']);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }

  var reader = new FileReader();
  this.imagePath = files;
  reader.readAsDataURL(files[0]); 
  reader.onload = (_event) => { 
    this.imgURL = reader.result; 
  }
}

/* preview(files) {
  if (files.length === 0)
    return;

  
} */




  reset() {
    this.title = '';
    this.subtitle = '';
    this.topic = '';
    this.myInputVariable.nativeElement.value = '';
    this.imgURL = '';
    this.url = '';
  }
  upload() {

    const data = new FormData();
  
    data.append('title', this.title );
    data.append('subtitle', this.subtitle);
    data.append('topic', this.topic);
    data.append('image', this.fileToUpload);
    data.append('url', this.url);

    this.http.post('https://digitaltag.tag8.in/api/postnotification/', data ,{
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    } ).subscribe((response) => {
      console.log(response);
      this.snackBar.openFromComponent(AddnotificationsComponent, {duration: 500});
       this.snackBar.open('Notification Sent ', 'Undo', {
         duration: 1000
       });
       window.location.reload();
    });


  }


  sendInactive() {

    const data = new FormData();
  
    data.append('title', this.title );
    data.append('subtitle', this.subtitle);
    data.append('topic', this.topic);

    data.append('image', this.fileToUpload);
    

    this.http.post('http://digitaltag.tag8.in/api/notifiyinactiveusers', data ,{
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    } ).subscribe((response) => {
      console.log(response);
      this.snackBar.openFromComponent(AddnotificationsComponent, {duration: 500});
       this.snackBar.open('Notification Sent ', 'Undo', {
         duration: 1000
       });
       window.location.reload();
    });


  }




















  




}
