import { Component, OnInit   , ViewChild, AfterViewInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UseriddetailsService } from '../useriddetails.service';
import { Subject } from 'rxjs';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource , MatSort , MatPaginator } from '@angular/material';
import { Router, Params } from '@angular/router';
import { Users } from './users.model';
import { Found } from './found.model';
import { Alerts } from './alert.model';

@Component({
  selector: 'app-cchome',
  templateUrl: './cchome.component.html',
  styleUrls: ['./cchome.component.css']
})
export class CchomeComponent implements OnInit   , AfterViewInit {
  dispalyedColumns = [ 'uid' , 'firstname', 'email', 'phone'   , 'view'];
  dispalyedColumns2 = [ 'uid' , 'name', 'geolocation', 'imei'  , 'timestamp'];
  dispalyedColumns3 = [ 'uid' , 'name', 'geolocation', 'imei'  , 'timestamp'];

  dataSource = new  MatTableDataSource<Users>();
  dataSource2 = new  MatTableDataSource<Found>();
  dataSource3 = new MatTableDataSource<Alerts>();

  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;


  @ViewChild('table1', {read: MatSort}) firstDataSort: MatSort;
  @ViewChild('page1', {read: MatPaginator}) firstDataPaginator: MatPaginator;

  @ViewChild('table2', {read: MatSort}) secondDataSort: MatSort;
  @ViewChild('page2', {read: MatPaginator}) secondDataPaginator: MatPaginator;

  @ViewChild('table3', {read: MatSort}) thirdDataSort: MatSort;
  @ViewChild('page3', {read: MatPaginator}) thirdDataPaginator: MatPaginator;






  imei;
  image;
  newForm = false;
  data1;
  places;
  details;
  counts;
  found;
  alert;
  img;
  thirdarr = [];
  hide = 'block';
  show = 'none';
  alerts = 'none';

  public origin: any;
  public destination: any;

  uid;


  profileForm: FormGroup;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    public  http: HttpClient,
    public uidService: UseriddetailsService,
    public snackBar: MatSnackBar,
    private router: Router,

  ) {

  }




  

  ngOnInit(): void {





    console.log(sessionStorage.getItem('token'));



    const obs = this.http.get('https://digitaltag.tag8.in/api/getUsers', {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {
      const data = [];
      console.log(response);
      data.push(response);

      this.data1 = data;
      console.log(this.data1);
       this.dataSource.data = response;

    });

    const found = this.http.get('https://digitaltag.tag8.in/api/foundDetails/', {
      headers: {'Authorization': 'Bearer ' +   sessionStorage.getItem('token')}
    });
    found.subscribe((response: any) => {
       this.dataSource2.data = response;
    });


    const alert = this.http.get('https://digitaltag.tag8.in/api/alertDetails/', {
      headers: {'Authorization': 'Bearer ' +   sessionStorage.getItem('token')}
    });
    alert.subscribe((response: any) => {
      console.log(response);
       this.dataSource3.data = response;
    });



    const  usercount = this.http.get('https://digitaltag.tag8.in/api/userCount/', {
      headers: {'Authorization': 'Bearer ' +   sessionStorage.getItem('token')}
    });
    usercount.subscribe((response) => {
     this.counts = response;
     console.log(this.counts);
    });

    const  rtfoundcount = this.http.get('https://digitaltag.tag8.in/api/foundCount/', {
      headers: {'Authorization': 'Bearer ' +   sessionStorage.getItem('token')}
    });
    rtfoundcount.subscribe((response) => {
       this.found = response;
    });

    const  alertcount = this.http.get('https://digitaltag.tag8.in/api/alertCount/', {
      headers: {'Authorization': 'Bearer ' +   sessionStorage.getItem('token')}
    });
    alertcount.subscribe((response) => {
       this.alert = response;
    });

  }



  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required ]
    });
  }

  save(value) {
    this.userService.updateCurrentUser(value)
    .then(res => {
      console.log(res);
    }, err => console.log(err));
  }

  logout() {
    sessionStorage.removeItem('token');
      console.log('tokenremoved');
      this.router.navigate(['/login']);
  }

  setUid = function (user) {
    this.uidService.getuid(user);
    localStorage.setItem('userid', this.uidService.uid);
  };

  
  getRegistered() {
    this.hide = 'block';
     this.show = 'none';
     this.alerts = 'none';
  };

  displayFound = function() {
     this.alerts = 'none';
      this.hide = 'none';
     this.show = 'block';
  };

   displayAlerts = function() {
     this.alerts = 'block';
     this.show = 'none';
     this.hide = 'none';

   };


  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase( );
  }

  doFilter1(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase( );
  }

  doFilter2(filterValue: string) {
    this.dataSource3.filter = filterValue.trim().toLowerCase( );
  }

  ngAfterViewInit() {
    //  this.dataSource.sort = this.sort;
    //  this.dataSource.paginator  = this.paginator;
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator  = this.firstDataPaginator;
    this.dataSource2.sort = this.secondDataSort;
    this.dataSource2.paginator  = this.secondDataPaginator;
    this.dataSource3.sort = this.thirdDataSort;
    this.dataSource3.paginator  = this.thirdDataPaginator;


  }

}
