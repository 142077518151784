import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth.service'
import { UserService } from '../../../core/user.service'
import { Router, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  userid; 
  //Data to be displayed on the navbar
   name:string;
   photo:string;
   email:string;
  constructor(public http: HttpClient,public userService: UserService,public authService: AuthService,private router: Router) { }

  logout() {
    this.authService.doLogout()
      .then((res) => {
        this.router.navigate(['/login']);
      }, (error) => {
        console.log('Logout error', error);
      });
  }

  ngOnInit() {
    this.userService.getCurrentUser().then((res) => {
      const email = res.providerData[0].email;
       
        this.email =  res.providerData[0].email,
        this.photo =  res.photoURL,
         this.name = res.displayName
      

      this.http.get('https://digitaltag.tag8.in/api/getUsersByEmail/' + email, {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
      }).subscribe((response: any) => {
        this.userid = response.data.uid;
        console.log(this.userid);
        this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
          headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
        }).subscribe((re: any) => {


        })

      })
    });




  }

}
