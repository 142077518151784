import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './core/auth.guard';
import { MapviewComponent } from './mapview/mapview.component';
import { LocationmapComponent } from './locationmap/locationmap.component';

import { ItemlistComponent } from './itemlist/itemlist.component';
import { AddnotificationsComponent } from './addnotifications/addnotifications.component';


import { InactiveusersComponent } from './inactiveusers/inactiveusers.component';

import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { CouponComponent } from './coupon/coupon.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';

import { UserListComponent } from './user-list/user-list.component';
import { CchomeComponent } from './cchome/cchome.component';
import { LocationComponent } from './location/location.component';
import { MobileViewPoliceCompComponent } from './mobile-view-police-comp/mobile-view-police-comp.component';
import { MobileViewAboutUsComponent } from './mobile-view-about-us/mobile-view-about-us.component';
import { FaqComponent } from './faq/faq.component';

/* User Premium Component Start */
import { UserPremiumComponent } from './user-premium/user-premium.component';
import { DashboardComponent } from './user-premium/pages/dashboard/dashboard.component';
import { FileComplaintComponent } from './user-premium/pages/FileComplaint/FileComplaint.component';
import { UserProfileComponent } from './user-premium/pages/UserProfile/UserProfile.component';
import { Maps1Component } from './user-premium/pages/maps/maps.component';
/* User Premium Component Ends */

/* Admin Component Start */
import { UserAdminComponent } from './user-admin/user-admin.component';
import { AdmDashboardComponent } from './user-admin/pages/dashboard/dashboard.component';
import { AddUserComponent } from './user-admin/pages/add-user/add-user.component';
import { CouponsComponent } from './user-admin/pages/coupons/coupons.component';
import { ItemListComponent } from './user-admin/pages/ItemsList/Itemslist.component';
import { GeoLocationComponent } from './user-admin/pages/maps/maps.component'
import { MembersListComponent } from './user-admin/pages/member-list/member-list.component';

import { UserAdminViewComponent } from './user-admin-view/user-admin-view.component';
import { UADashboardComponent } from './user-admin-view/pages/dashboard/dashboard.component';
import { UAFileComplaintComponent } from './user-admin-view/pages/FileComplaint/FileComplaint.component';
import { UAMapsComponent } from './user-admin-view/pages/maps/maps.component';
import { UAUserProfileComponent } from './user-admin-view/pages/UserProfile/UserProfile.component';

/* Admin Component Ends */

/* CCUser Component Starts */
import { CcUserComponent } from './customer_care/ccuser.component';
import { CcDashboardComponent } from './customer_care/pages/dashboard/dashboard.component';
import { CcMapsComponent } from './customer_care/pages/maps/maps.component';
import {TasklistComponent} from './customer_care/pages/tasklist/tasklist.component';
/* CCUser Component Ends */

/* Free user Component Starts */
import { UserFreeComponent } from './user-free/user-free.component';
import { MapComponent } from './user-free/views/maps/map.component';
import { FreeDashboardComponent } from './user-free/views/dashboard/dashboard.component';
import { UpgradepremiumComponent } from './user-free/views/upgradepremium/upgradepremium.component';
/* Free User Component Ends */

import { BlogPageComponent } from './blog-page/blog-page.component';
import { Blog1Component } from './blog-page/blog1/blog1.component';
import { Blog2Component } from './blog-page/blog2/blog2.component';
import { Blog3Component } from './blog-page/blog3/blog3.component';
import { Blog4Component } from './blog-page/blog4/blog4.component';
import { UserguideComponent } from './userguide/userguide.component';

/*  ReportFound Component */


import { ReportfoundComponent } from './reportfound/reportfound.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const rootRouterConfig: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  
  { path: 'map', component: MapviewComponent,  },
  { path: 'locationmap', component: LocationmapComponent,  },
  
  { path: 'itemlist', component: ItemlistComponent,  },
  { path: 'addnotifications', component: AddnotificationsComponent },
    
  { path: 'inactiveusers', component: InactiveusersComponent },


  { path: 'coupon', component: CouponComponent },
  { path: 'terms-and-conditions', component: TermsConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'reportfound/:oid', component: ReportfoundComponent },
  { path: 'userslist', component: UserListComponent },
  { path: 'cchome', component: CchomeComponent },
  { path: 'location/:id', component: LocationComponent },
  { path: 'filecomp/:uid/:imei', component: MobileViewPoliceCompComponent },
  { path: 'aboutus', component: MobileViewAboutUsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'blog-page', component: BlogPageComponent},
  { path: 'blog1', component: Blog1Component},
  { path: 'blog2', component: Blog2Component},
  { path: 'blog3', component: Blog3Component},
  { path: 'blog4', component: Blog4Component},
  { path: 'userguide' ,component: UserguideComponent},
  {
    path: 'UserPremium',
    component: UserPremiumComponent,
    children: [
      { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
      { path: 'Dashboard', component: DashboardComponent },
      { path: 'UserProfile', component: UserProfileComponent },
      { path: 'FileComplaint', component: FileComplaintComponent },
      { path: 'maps1', component: Maps1Component }]
  },
  {
    path: 'UserAdmin',
    component: UserAdminComponent,
    children: [
      { path: '', redirectTo: 'AdminDashboard', pathMatch: 'full' },
      { path: 'AdminDashboard', component: AdmDashboardComponent },
      { path: 'AddUser', component: AddUserComponent },
      { path: 'Coupons', component: CouponsComponent },
      { path: 'ItemList', component: ItemListComponent },
      { path: 'GeoLocation', component: GeoLocationComponent },
      { path: 'Members', component: MembersListComponent }]
  },
  {
    path: 'UserAdminView',
    component: UserAdminViewComponent,
    children: [
      { path: '', redirectTo: 'UADashboard', pathMatch: 'full' },
      { path: 'UADashboard', component: UADashboardComponent },
      { path: 'UAUserProfile', component: UAUserProfileComponent },
      { path: 'UAFileComplaint', component: UAFileComplaintComponent },
      { path: 'UAmaps', component: UAMapsComponent }]
  },

  {
    path: 'CCuser',
    component: CcUserComponent,
    children: [
      { path: '', redirectTo: 'CCDashboard', pathMatch: 'full' },
      { path: 'CCDashboard', component: CcDashboardComponent },
      { path: 'CCMaps', component: CcMapsComponent },
      { path: 'tasklist', component: TasklistComponent }
    ]
  },
  {
    path: 'FreeUser',
    component: UserFreeComponent,
    children: [
      { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
      { path: 'Dashboard', component: FreeDashboardComponent },
      { path: 'map', component: MapComponent },
      { path: 'upgrade', component: UpgradepremiumComponent }
    
    ]
  },



];

