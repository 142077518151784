import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    public authService: AuthService,
  ) {}

  canActivate(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
      .then(user => {
        window.alert("you were logged out");
        this.authService.doLogout()
        .then((res) => {
          sessionStorage.removeItem('token');
          console.log('tokenremoved');
           this.router.navigate(['/home']);
        }, (error) => {
          console.log('Logout error', error);
        });      
        return resolve(false);
      }, err => {
        return resolve(true);
      })
    })
  }
}
