import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Component, OnInit, ViewChild, AfterViewInit, Inject, HostListener } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Free } from './free.model';
import { Premium } from './premium.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-members-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MembersListComponent implements OnInit {


  // TO Display Count of Free Users and Premium Users
  freeCount: String;
  premiumCount: String;


  hide = 'block';
  show = 'none';

  dispalyedColumns = ['firstname', 'model', 'brand', 'last_known_location'];
  dispalyedColumns1 = ['firstname', 'model', 'brand', 'last_known_location', 'membership_expiry_date'];
  dataSource = new MatTableDataSource<Free>();
  dataSource1 = new MatTableDataSource<Premium>();

  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;

  @ViewChild('table2', { read: MatSort }) secondDataSort: MatSort;
  @ViewChild('page2', { read: MatPaginator }) secondDataPaginator: MatPaginator;

  constructor(public http: HttpClient) { }

  getRegistered() {
    this.hide = 'block';
    this.show = 'none';
  };

  displayAlerts = function () {
    this.hide = 'none';
    this.show = 'block';
  };

  ngOnInit() {

    const premium = this.http.get('https://digitaltag.tag8.in/api/getuserpremium', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    premium.subscribe((response: any) => {
      this.dataSource1.data = response.data.userdata;
      console.log(response.data.userdata);
      this.premiumCount = response.data.count;

    });

    const freeuser = this.http.get('https://digitaltag.tag8.in/api/getfreeusers', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    freeuser.subscribe((response: any) => {
      this.dataSource.data = response.data.userdata;
      this.freeCount = response.data.count;
    });


  }

  doFilter(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  doFilter1(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;
    this.dataSource1.sort = this.secondDataSort;
    this.dataSource1.paginator = this.secondDataPaginator;
  }

}
