import { Component, OnInit , ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../core/auth.service'
import { UserService } from '../../../core/user.service';
import { MatSnackBar } from '@angular/material';
import { LightboxConfig, Lightbox } from 'ngx-lightbox';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface DialogData {
  fcm_token:any,
  mdl:any,
  imi:any
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {




//To Pass ImageURL ,Name and email
  userData;
  //Variables to display message on ringing 
  ring = 'Ring Device';
  lost = 'Lost Mode';
  //variables for hiding and showing
  show = 'none';
  hide = 'block';
  activate = 'block';
  deactivate = 'none';
  userid: any;
  userAssets: any;

  message = 'Test';

  //to display Logs 
  imagesLength;
  images;
  imageData;
  _album = [];
  _albums = [];
  batteryinfo;
  wifiInfo;
  firstInterval;
  secondInterval;


  constructor(
    public authService: AuthService,
    public userService: UserService,
    private router: Router,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private _lightbox: Lightbox,
    public dialog: MatDialog,
  ) { }


  getfcmToken(fcmtoken) {
    this.ring = 'Ringing';
    const flag = 'Device Located';
    console.log(fcmtoken);
    const body = { token: fcmtoken, title: flag };
    this.http.post('https://digitaltag.tag8.in/api/pushnotification/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Ringing', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.hide = 'none';
    this.show = 'block';

  }

  ringOff(fcmtoken) {
    this.show = 'none';
    this.ring = 'Ring Device';
    const flag = 'Stop Ringing';
    const body = { token: fcmtoken, title: flag };
    this.http.post('https://digitaltag.tag8.in/api/pushnotification/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Stoppped Ringing', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.show = 'none';
    this.hide = 'block';
  }

  lostmode(fcmtoken, imei) {

    this.lost = 'Lost Mode On';
    console.log(imei);
    const msg = 'Lost Mode Activated';
    const msgbody = 'Lost Mode has been Activated. This Phone is being Tracked';
    const st = 'Device was reported as Lost';
    const body = { imei: imei, token: fcmtoken, title: msg, subtitle: msgbody, status: st };
    this.http.post('https://digitaltag.tag8.in/api/lostmodeactivate/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Lost Mode Activated', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.activate = 'none';
    this.deactivate = 'block';

  }

  securemode(fcmtoken, imei) {

    this.lost = 'Lost Mode';
    const msg = 'Lost Mode Deactivated';
    const msgbody = 'Lost Mode has been Deactivated. Your Phone is Secure';
    const st = 'SECURE';
    const body = { imei: imei, token: fcmtoken, title: msg, subtitle: msgbody, status: st };
    this.http.post('https://digitaltag.tag8.in/api/lostmodeactivate/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('Lost Mode  Deactivated', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });
    this.activate = 'block';
    this.deactivate = 'none';



  }



  sendSMS(uid , imei) {
    console.log(uid,imei);
    const body = { imei: imei, uid:uid };
    this.http.post('https://digitaltag.tag8.in/api/sendSMS/', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((response) => {
      this.snackBar.open('SMS Delivered', 'Undo', {
        duration: 3000
      });
      console.log(response);
    });

  }



  lockdevice(fcmtoken , imei ) {


    console.log('Lock Device');
    const msg = 'Device Locked';
    const msgbody = 'Locking Device';
  
    const body =  { imei: imei  , token: fcmtoken , title: msg  };
    this.http.post('https://digitaltag.tag8.in/api/lockdevice' , body , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken')}
    }).subscribe((response) => {
      this.snackBar.open('Device Locked', 'Undo', {
        duration: 3000
      });
   console.log(response);
    });
  
  
   }
  


   warningwipedata(fcmtoken , imei, model) {
   
    const dialogRef = this.dialog.open(warningwipedataDialog, {
      width: '350px',
      data:{fcm_token:fcmtoken,mdl:model,imi:imei},
      
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  tabClick(tab) {

    clearInterval(this.firstInterval);
    clearInterval(this.secondInterval);
    for(let i=0; i<this.userAssets.length; i++)
    {

      if (tab.index === i  ) {
        
            var dt = localStorage.getItem('model');
            if(typeof(dt) === undefined) {
              
              localStorage.setItem("model", this.userAssets[i].model);
              localStorage.setItem("device_id", this.userAssets[i].device_id);
              

              
              
              let device_id = localStorage.getItem('device_id'); 
              this.getActivityLogs(this.userid,device_id);

              
                
              this.firstInterval = setInterval( ()=> { this.getbatteryDetails(this.userid,device_id) } ,5000); 
          
            
          

              break;
            
              
            }  else  {
              localStorage.removeItem("model");
              localStorage.removeItem("device_id");
              localStorage.setItem("model", this.userAssets[i].model);
              localStorage.setItem("device_id", this.userAssets[i].device_id);
              var dt1 = localStorage.getItem('model');
              
              let device_id = localStorage.getItem('device_id'); 
              this.getActivityLogs(this.userid,device_id);
        
             this.secondInterval = setInterval( ()=> { this.getbatteryDetails(this.userid,device_id) } ,5000);
          
            
              break;
              
            }
          
      }
    }
    
  }

  logout() {
    this.authService.doLogout()
      .then((res) => {

        this.router.navigate(['/login']);
      }, (error) => {
        console.log('Logout error', error);
      });
  }



  getActivityLogs(uid , device_id) {
    //TODO: Write the implemetation of the function
    this._albums = [];
    
    // const len = this.userAssets.length;
    // console.log(len);
    // for (let i = 0; i < len; i++) {
      
      this.http.get('https://digitaltag.tag8.in/api/activitiesimage/' + uid + '&' + device_id, { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') } }).subscribe((resp: any) => {

      console.log(uid);
      console.log(device_id);
      console.log(sessionStorage.getItem('usertoken'));
      const le = this.imagesLength;
    
      for (let k = 0; k < le; k++) {
          for (let j = 0; j < resp.data.length; j++) {
            
           
            const st1 = resp.data[j].status;
            const tmp = resp.data[j].timestamp;
            const im = resp.data[j].device_id;
            const str = this.imageData[k].key;
            const getimei = str.indexOf('_') + 1;
            const getimeival = str.indexOf('_', getimei);
            const imeival = str.substring(getimei, getimeival);
            const start_pos = str.indexOf('_') + 1;
            const end_pos = str.indexOf('_', start_pos);
            const result1 = str.substring(start_pos);
            const timest = result1.lastIndexOf('_');
            const time = result1.substr(timest + 1);
            const st = result1.indexOf('_') + 1;
            const ep = result1.indexOf('_', st);
            const status = result1.substring(st, ep);
           
              
            if (tmp === time && st1 === status && imeival == device_id) {
              console.log(imeival);
              const src = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + this.imageData[k].key;
              const thumb = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + this.imageData[k].key;
              const album = {
                src: src,
                thumb: thumb,
                time: tmp,
                status: status,
                imei: im,
                
                latitude: resp.data[j].latitude,
                longitude: resp.data[j].longitude

              };
           
              this._albums.push(album);
              
              this._albums.sort((val1: any, val2: any) => { return +new Date(val2.time) - +new Date(val1.time) })
              
              
              break;
            }
          }
        }
      });
    //}


  }





  


  ngOnInit() {

   

    this.userService.getCurrentUser().then((res) => {
      const email = res.email;
       this.userData = {
        email: res.email,
        photo: res.photoURL,
        name:res.displayName
      }

      this.http.get('https://digitaltag.tag8.in/api/getUsersByEmail/' + email, {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
      }).subscribe((response: any) => {
        this.userid = response.data.uid;
        console.log(this.userid);
        this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
          headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
        }).subscribe((re: any) => {
          this.userAssets = re.data;
          this.imagesLength =  re.image.length;
          this.imageData = re.image;
          console.log(this.userAssets[0].battery);
        
      
          //End of the Activity Logs Image
        });
      });

    });

  }

  getbatteryDetails(uid , device_id ){
    
    // console.log("Method Invoked");
    // console.log(device_id);
    this.http.get('https://digitaltag.tag8.in/api/devicedetails/' + device_id + '&' + uid ,  {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
    }).subscribe((re: any) => {
      
      this.batteryinfo = re.data[0].battery;
      this.wifiInfo = re.data[0].wifi;
      // console.log(this.batteryinfo);
     
      
  });
  }

  open(i) {
    // open lightbox
    this._lightbox.open(this._albums, i, { wrapAround: true, showImageNumberLabel: true });

    
  }

  close() {
    // close lightbox programmatically
    this._lightbox.close();
  }

}


@Component({
  selector: 'dialog-warning-wipedata-dialog',
  templateUrl: 'dialog-warning-wipedata-dialog.html',
})
export class warningwipedataDialog {
  /* model = localStorage.getItem('model'); */
  // fcm_token:any;
  // mdl:any;
  // imi:any;
  device;
  constructor(
    public dialogRef: MatDialogRef<warningwipedataDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public snackBar: MatSnackBar,public  http: HttpClient) {}
    
   
      


    wipeData(fcmtoken , imei) {

      console.log('Display Data');
      console.log(fcmtoken);
      console.log(imei);
    
      const msg = 'Wiping Data';
      const msgbody = 'Erasing Data';
    
      const body =  { imei: imei  , token: fcmtoken , title: msg , subtitle: msgbody };
      this.http.post('https://digitaltag.tag8.in/api/wipeuserdata' , body , {
        headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken')}
      }).subscribe((response) => {
        this.snackBar.open('Wiping Data', 'Undo', {
          duration: 3000
        });
     console.log(response);
      });
     }

     onNoClick(): void {
      this.dialogRef.close();
      /* localStorage.removeItem('model'); */
    }
}
