import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-mobile-view-police-comp',
  templateUrl: './mobile-view-police-comp.component.html',
  styleUrls: ['./mobile-view-police-comp.component.css']
})
export class MobileViewPoliceCompComponent implements OnInit {

  Police_StNa :any;
  description :any;
  BnIn :any;
  purchde : any;
  userid : any;
  imei: any;
  email: any;

  constructor(public http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private location: Location,
    private router: Router,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog) { }

  fileComplaint()  {

    console.log('INVOKD');
      const body = {
        
        imei: localStorage.getItem('imei'),
        uid:localStorage.getItem('uid'),
        invoiceno:this.BnIn,
        idesc:this.description,
        police:this.Police_StNa,
        pdt:this.purchde,
        email:this.email
              };
              console.log(this.Police_StNa);
              console.log(this.email);
      const obj = this.http.post('https://digitaltag.tag8.in/api/filecomplaint' , body , {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
      } );
      this.spinner.show();
      obj.subscribe((response: any) => {
        this.spinner.hide();
        /* console.log(response); */
        
        if(response.data == 'Mail Delivered')
        {
          console.log(response);
          this.snackBar.open('Report have been Mailed to your Inbox','Ok',{
              duration:5000
          });
        }
        else if(response.data == 'Invalid Mail')
        {
          console.log(response);
          this.snackBar.open('Invalid Email', 'Ok', {
            duration: 5000
          });
        }  
        localStorage.removeItem('uid');
        localStorage.removeItem('imei');
        this.router.navigate(['/home']);
      });
      
    this.BnIn=' ';
    this.Police_StNa=' ';
    this.description=' ';
    this.purchde=' ';
  }
 


  ngOnInit() {
    this.userid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.imei = this.activatedRoute.snapshot.paramMap.get('imei');
      // this.activatedRoute.queryParams.subscribe(params => {
        
      //   console.log(params['uid']);
      // });

    console.log(this.userid);
    console.log(this.imei);
    localStorage.setItem("uid",this.userid);
    localStorage.setItem("imei",this.imei);

  }
}




