import { Component , OnInit , ViewChild  , OnDestroy  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MouseEvent, AgmMap } from '@agm/core';
import { TravelMarker, TravelMarkerOptions, TravelData, TravelEvents } from 'travel-marker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../core/auth.service';
import { Location } from '@angular/common';

declare var google: any;
@Component({
  selector: 'app-locationmap',
  templateUrl: './locationmap.component.html',
  styleUrls: ['./locationmap.component.css']
})

export class LocationmapComponent  implements OnInit  , OnDestroy {

 // google maps zoom level
 zoom: number =  20;

 // initial center position for the map
 lat: number =  19.016439;
 lng: number = 72.829422;
 showmap: true;
 userid: any;
  startDate: any;
 endDate: any;
 origin: any;
 destination: any;
 waypts = [];
 odpts = [];

 map: any;
 line: any;
 directionsService: any;
 marker: TravelMarker = null;

 // speedMultiplier to control animation speed
 speedMultiplier = 1;

 constructor( public  http: HttpClient ,
        public authService: AuthService , private location: Location) {}
 onMapReady(map: any) {
   console.log(map);
   this.map = map;
 }

 ngOnInit() {  }


 // get locations from direction service
 calcRoute(  a, b) {
   console.log('calcroute');
   this.line = new google.maps.Polyline({
     strokeOpacity: 0.5,
     path: [],
     map: this.map
   });
 
    const start = new google.maps.LatLng(19.01642, 72.830790001);
    const end = new google.maps.LatLng(19.01507, 72.83531);

   console.log(this.waypts);
 const  request = {
     origin: start,
     destination: end,
     waypoints:  this.waypts,
     optimizeWaypoints: false,
     travelMode: google.maps.TravelMode.DRIVING
 };


   this.directionsService = new google.maps.DirectionsService;
   this.directionsService.route(request, (response, status) => {
     console.log(status);
     if (status === google.maps.DirectionsStatus.OK) {
       const legs = response.routes[0].legs;
       for (let i = 0; i < legs.length; i++) {
         const steps = legs[i].steps;
         for (let j = 0; j < steps.length; j++) {
           const  nextSegment = steps[j].path;
           for (let k = 0; k < nextSegment.length; k++) {
             this.line.getPath().push(nextSegment[k]);
           }
         }
       }
       this.initRoute();
     }
   });
 }





 getDates() {

    
     const  start =  this.startDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '00:30');
      const  fin =  this.endDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '13:00');

      const uid = 'LR9i8YhxxqQJYqhXmLmN0pmnTzI3';

    const loc = this.http.get('https://digitaltag.tag8.in/api/getlocationbydate/' + uid 
    + '&' + start + '&' + fin , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
           });
   loc.subscribe((response: any) => {
       const ldata = [];

    for (let i = 0; i < response.data.length ; i++) {
       ldata.push(response.data[i]); }
       ldata.forEach(element => {
         const loc = {
            lat: element._id.latitude,
            lng: element._id.longitude

         };

        this.odpts.push(loc);

        const dir = {
           location: {
             lat:  parseFloat(element._id.latitude) ,
            lng: parseFloat(element._id.latitude)

           }


        };

        this.waypts.push(dir);

       });

          const index = this.odpts.length - 1;

          console.log(this.odpts[0]);
          console.log(this.odpts[index]);


        this.calcRoute(this.odpts[0], this.odpts[index]);
     });
   }


 // initialize travel marker
 initRoute() {
   const route = this.line.getPath().b;
   // options
   const options: TravelMarkerOptions = {
     map: this.map,  // map object
     speed: 50,  // default 10 , animation speed
     interval: 10, // default 10, marker refresh time
     speedMultiplier: this.speedMultiplier,
     markerOptions: {
       title: 'Travel Marker',
       animation: google.maps.Animation.DROP,
       icon: {
         url: './assets/map icongreen.png',
         // This marker is 20 pixels wide by 32 pixels high.
         animation: google.maps.Animation.DROP,
         // size: new google.maps.Size(256, 256),
         scaledSize: new google.maps.Size(120, 80),
         // The origin for this image is (0, 0).
         origin: new google.maps.Point(0, 0),
         // The anchor for this image is the base of the flagpole at (0, 32).
         anchor: new google.maps.Point(53, 110)
       }
     },
   };

   // define marker
   this.marker = new TravelMarker(options);

   // add locations from direction service
   this.marker.addLocation(route);

   setTimeout(() => this.play(), 2000);
 }

  ngOnDestroy() {
    console.log('Uid Cleared');
  localStorage.removeItem('userid');
  }

clearUid() {
  console.log('Uid Cleared');
  localStorage.removeItem('userid');
}

 // play animation
 play() {
  this.marker.play();
 }

 // pause animation
 pause() {
   this.marker.pause();
 }

 // reset animation
 reset() {
   this.marker.reset();
 }

 // jump to next location
 next() {
   this.marker.next();
 }

 // jump to previous location
 prev() {
   this.marker.prev();
 }

 // fast forward
 fast() {
   this.speedMultiplier*=2;
   this.marker.setSpeedMultiplier(this.speedMultiplier);
 }

 // slow motion
 slow() {
   this.speedMultiplier/=2;
   this.marker.setSpeedMultiplier(this.speedMultiplier);
 }






 
 logout() {
  this.authService.doLogout()
  .then((res) => {
    sessionStorage.removeItem('token');
    this.location.back();
  }, (error) => {
    console.log('Logout error', error);
  });
}




}
