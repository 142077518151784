import { Component, OnInit, HostListener } from '@angular/core';
import { ScrollToService } from 'ng2-scroll-to-el';
declare var gtag;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;

  collapsed = true;

  constructor(private scrollService: ScrollToService) { }

  @HostListener('window:scroll', [])
    onWindowScroll() 
    {
      if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) 
      {
          this.showScroll = true;
      } 
      else if ( this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) 
      { 
        this.showScroll = false; 
      }
    }



  ngOnInit() {
  }

  scrollToTop() 
    { 
      (function smoothscroll() 
      { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
        if (currentScroll > 0) 
        {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 5));
        }
      })();
    }

  getNGLanding() {
    window.open('https://bit.ly/3qgt9um');
  //   window.location.href = "assets/Digital_Tag_Find_My_Phone.apk";
  //   gtag('event', 'conversion', {
  //     'send_to': 'AW-828190185/Fsq5CO-f1OMCEOnb9IoD'
  // });
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  scrollToIntro(element) {
    this.scrollService.scrollTo(element, 700, -70);
  }

  scrollToFeatures(element) {
    this.scrollService.scrollTo(element, 700, -90);
  }

  scrollToSnapshots(element) {
    this.scrollService.scrollTo(element, 700, -90);
  }

  scrollToTestimonials(element) {
    this.scrollService.scrollTo(element, 700, -65);
  }

  scrollToPricing(element) {
    this.scrollService.scrollTo(element, 700, -105);
  }

  scrollToAbout(element) {
    this.scrollService.scrollTo(element, 700, -30);
  }

  scrollToBuy(element) {
    this.scrollService.scrollTo(element, 700, -30);
  }
}
