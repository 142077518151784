import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth.service'
import { Router, Params } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private appService: AppService,public authService: AuthService,private router: Router) { }
  isCollapsed = true;
  ngOnInit() {

  


  }

  logout() {
    this.authService.doLogout()
      .then((res) => {

        this.router.navigate(['/login']);
      }, (error) => {
        console.log('Logout error', error);
      });
  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

}
