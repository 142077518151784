import { Component, OnInit } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';

@Component({
  selector: 'app-buy',
  templateUrl: './buynow.component.html',
  styleUrls: ['./buynow.component.css']
})
export class BuynowComponent implements OnInit {

  public carouselOptions: NguCarousel;
  public portfolios = [{
    photo: 'assets/images/DL1.jpg',
  }, {
    photo: 'assets/images/DL2.png',
  }, {
    photo: 'assets/images/DL3.png',
  }, {
    photo: 'assets/images/DL4.png',
  }, {
    photo: 'assets/images/DL5.png',
  }, {
    photo: 'assets/images/DL6.png',
  }, {
    photo: 'assets/images/DL7.png',
  }]

  constructor() { }

  ngOnInit() {

    this.carouselOptions = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 2,
      speed: 1200,
      interval: 2000,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true
    }
  }

  opensite(){
    window.open('https://bit.ly/3gWFNGP');
  }

}
