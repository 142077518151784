import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { AuthService } from '../core/auth.service';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  myStyle: object = {};
  myParams: object = {};
  width = 100;
  height = 100;

  loginForm: FormGroup;
  errorMessage = '';

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private _firebaseAuth: AngularFireAuth,
    private elementRef: ElementRef
  ) {
    this.createForm();
  }
  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
     this._firebaseAuth.auth.signInWithPopup(provider).then((result) => {
      const login = this.http.get('https://digitaltag.tag8.in/api/verifyuser/' + result.user.email);
      login.subscribe((response: any) => {
        console.log(response);
        if (response.hasOwnProperty('status')) {


          if (response.plan == 'FREE') {
            sessionStorage.setItem('usertoken', response.token);
            console.log(response.data.uid);
            sessionStorage.setItem('uid', response.data.uid);

            this.router.navigate(['/FreeUser']);
            this.snackBar.open('Login Successful', 'Valid User', {
              duration: 3000
            });

          } else if (response.plan == 'PAID') {
            sessionStorage.setItem('usertoken', response.token);
            console.log(response.data.uid);
            sessionStorage.setItem('uid', response.data.uid);

            this.router.navigate(['/UserPremium']);
            this.snackBar.open('Login Successful', 'Valid User', {
              duration: 3000
            });

          }

        } else {
          console.log('Invalid Login');
          this.snackBar.open('Invalid login Paramteres', 'Error', {
            duration: 3000
          });
        }
      });
    });


  }



  ngOnInit() {
    this.myStyle = {
      'position': 'fixed',
      'width': '100%',
      'height': '100%',
      'z-index': -1,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
      /* 'background-color': 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)', */
      'background-color': '#000000',
    };

    this.myParams = {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: '#ffc107'
        },
        shape: {
          type: 'circle',
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: 0.9,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 5,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#ffffff',
          opacity: 0.6,
          width: 1
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'grab'
          },
          onclick: {
            enable: true,
            mode: 'push'
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true,
    };


  }
  ngAfterViewInit() {
    /*   this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#1f1f1f'; */
  }

}

