import { Component, OnInit } from '@angular/core';
import { AppService } from './services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {
  title = 'pro-dashboard-angular';
  constructor(private appService: AppService,
              private spinner: NgxSpinnerService) { }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  ngOnInit() {
    /** spinner module*/
    this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
    }, 10000);
  }

}
