import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../../../core/user.service';
import { MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-tables',
  templateUrl: './FileComplaint.component.html',
  styleUrls: ['./FileComplaint.component.scss']
})
export class UAFileComplaintComponent implements OnInit {

  psname = new FormControl('', [Validators.required]);
  bill = new FormControl('', [Validators.required]);
  desc = new FormControl('', [Validators.required]);
  pude = new FormControl('', [Validators.required]);

  userid: any;
  userAssets: any;
  Police_StNa: any;
  description: any;
  BnIn: any;
  purchde: any;
  email: any;
  imei: any;
  getErrorMessage() {
    this.psname.hasError('required') ? 'Please enter the Station Name' :
      '';
    this.bill.hasError('required') ? 'Please enter the Bill / Invoice No.' :
      '';
    this.desc.hasError('required') ? 'Please enter the Description' :
      '';
    return this.pude.hasError('required') ? 'Please enter the Store Name or Purchase Date' :
      '';
  }
  constructor(public http: HttpClient,
    public snackBar: MatSnackBar,
    public userService: UserService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.userid = sessionStorage.getItem('viewUser');
    this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((re: any) => {
      this.userAssets = re.data;
      localStorage.setItem("imei", this.userAssets[0].imei);
      localStorage.setItem("uid", this.userAssets[0].uid);
    });

  }


  fileComplaint() {
    const body = {
      imei: this.imei,
      uid: localStorage.getItem('uid'),
      invoiceno: this.BnIn,
      idesc: this.description,
      police: this.Police_StNa,
      pdt: this.purchde,
      email: this.email,
    };
    console.log(this.Police_StNa);
    console.log(this.imei);
    const obj = this.http.post('https://digitaltag.tag8.in/api/filecomplaint', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    this.spinner.show();
    obj.subscribe((response: any) => {
      this.spinner.hide();
      if (response.data == 'Mail Delivered') {
        console.log(response);
        this.snackBar.open('Report have been Mailed to your Inbox', 'Ok', {
          duration: 5000
        });
      }
      else if (response.data == 'Invalid Mail') {
        console.log(response);
        this.snackBar.open('Invalid Email', 'Ok', {
          duration: 5000
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      localStorage.removeItem('uid');

    });
  }


  clear() {
    this.imei = '';
    this.BnIn = '';
    this.description = '';
    this.Police_StNa = '';
    this.purchde = '';
    this.email = '';
  }

}
