import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-view-about-us',
  templateUrl: './mobile-view-about-us.component.html',
  styleUrls: ['./mobile-view-about-us.component.css']
})
export class MobileViewAboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
