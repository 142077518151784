import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from '../app.routes';  // Main Routes Import

//Material Module start
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';

import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserFreeComponent } from './user-free.component';
import { ViewsModule } from './views/views.module';
import { NgxSpinnerModule } from 'ngx-spinner';

import { NavigationModule } from './main-layout/navigation/navigation.module';
import {
  AgmCoreModule
} from '@agm/core';

@NgModule({
  declarations: [
    UserFreeComponent
  ],
  imports: [
    CommonModule,
    CollapseModule,
    ToastrModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: true }), 
    MatButtonModule,
    MDBBootstrapModule.forRoot(),
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NavigationModule,
    /* AppRoutes, */
    RouterModule,
    FormsModule,
    ViewsModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'AIzaSyBxzri4-_Mg7d7X1W5wodq6SPnxWSGdNBQ'
    }),
  ],
  providers: [],
  bootstrap: [UserFreeComponent],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class UserFreeModule { }
