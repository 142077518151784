import { Component, OnInit    } from '@angular/core';
import { AppService } from './services/app.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-user-premium',
  templateUrl: './user-premium.component.html',
  styleUrls: ['./user-premium.component.scss']
})
export class UserPremiumComponent implements OnInit  {
  title = 'pro-dashboard-angular';

  
 

  constructor(private appService: AppService,private spinner: NgxSpinnerService) {}
  //Getting the value from Dashboard Component
  message:string;
  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

 

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
     }, 6000);
  }





}
