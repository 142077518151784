import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-blog1',
  templateUrl: './blog1.component.html',
  styleUrls: ['./blog1.component.scss']
})
export class Blog1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
