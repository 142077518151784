import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth.service'
import { UserService } from '../../../core/user.service'
import { Router, Params } from '@angular/router';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(public userService: UserService,public authService: AuthService,private router: Router) { }

  logout() {
    this.authService.doLogout()
      .then((res) => {
        this.router.navigate(['/admin']);
      }, (error) => {
        console.log('Logout error', error);
      });
  }


  ngOnInit() {
  }

}
