import { ScrollToService } from 'ng2-scroll-to-el';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-userguide',
  templateUrl: './userguide.component.html',
  styleUrls: ['./userguide.component.scss']
})
export class UserguideComponent implements OnInit {
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;

  collapsed = true;

  constructor(private scrollService: ScrollToService) { }

  @HostListener('window:scroll', [])
  onWindowScroll() 
  {
    if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) 
    {
        this.showScroll = true;
    } 
    else if ( this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) 
    { 
      this.showScroll = false; 
    }
  }
 
  ngOnInit() {
  }

  scrollToTop(element) 
  { 
    this.scrollService.scrollTo(element, 700, -30);
  }
    /* (function smoothscroll() 
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) 
      {
        this.scrollService.scrollTo(element, 700, -30);
      }
    })();
  }
 */
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
  scrollToUserguide(element) {
    console.log("Scrolling");
    this.scrollService.scrollTo(element, 900, -30);
  }

  scrollToBlog_article(element) {
    this.scrollService.scrollTo(element, 900, -30);
  }
 
}
