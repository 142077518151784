import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../core/auth.service';
var start = 0 ; // Variable to get batch data


@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class GeoLocationComponent implements OnInit {
  lat: number = 19.0178254;
  lng: number = 72.8284586;
  places;
  locations;
  arr = [];
  icon = {
    url: './assets/map icon red.png',
    scaledSize: {
      width: 20,
      height: 20
    }
  };
  icon2 = {
    url: './assets/map icongreen.png',
    scaledSize: {
      width: 20,
      height: 20
    }
  };
  constructor(public http: HttpClient, public authService: AuthService) { }

  ngOnInit() {

    setInterval(() => {
        start+= 100;
        var temp =  sessionStorage.getItem('userscount');
        // console.log(temp);
        // console.log(start);
        if( start < parseInt(temp) ) {   
           var body ={
             start:start
          };
          const loc = this.http.post('https://digitaltag.tag8.in/api/locationdetails', body , {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
          });
          loc.subscribe((response: any) => {
            for(var i=0;i<response.data.length;i++) {
            //   this.locations.push(response.data[i]);
              this.arr.push(response.data[i]);
            }   
            this.places = this.arr;

          });
        }
    },2000);
  }

}
