import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
@NgModule({
    imports: [FileUploadModule],
    exports: [FileUploadModule]

})


export class FileModule {}

