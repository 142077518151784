import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { User } from './user.model';

export interface DialogData {
  email: string;
  password: string;
  type: string;
}
export interface DialogData1 {
  prev_email: string,
  email: string;
  password: string;
  type: string;
}


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  email: any;
  password: any;
  type: any;

  displayedColumns: string[] = ['email', 'firstname', 'type', 'createdon', 'actions'];
  dataSource = new MatTableDataSource<User>();;

  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;

  constructor(public dialog: MatDialog, public http: HttpClient) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateUserDialog, {
      width: '350px',
      data: {
        email: this.email,
        password: this.password,
        type: this.type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialog1(dat): void {
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '350px',
      data: {
        prev_email: dat.email,
        email: this.email,
        password: this.password,
        type: this.type
      }
    });
  }

  delUser(data): void {
    this.http.post('https://digitaltag.tag8.in/api/deleteCCuser/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
      location.reload();
    });
  }

  ngOnInit() {
    const userlist = this.http.get('https://digitaltag.tag8.in/api/getCCuser', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    userlist.subscribe((response: any) => {
      console.log(response);
      this.dataSource.data = response;
    });
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;
  }


}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'createuser.html',
  styleUrls: ['./createuser.css']
})
export class CreateUserDialog {

  hide = true;
  email: any;
  password: any;
  name: any;
  selected: 'CCuser';
  current_date = new Date();

  constructor(
    public dialogRef: MatDialogRef<CreateUserDialog>,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  addUser() {
    const data = {
      email: this.email,
      password: this.password,
      type: this.selected,
      firstname: this.name,
      createdOn: this.current_date
    };
    console.log(this.selected);
    this.http.post('https://digitaltag.tag8.in/api/addCCuser/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
      this.snackBar.open(' User Created  ', 'Undo', {
        duration: 3000
      });
      location.reload();
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'edituser.html',
  styleUrls: ['./edituser.css']
})
export class EditUserDialog {

  hide = true;
  email: any;
  password: any;
  name: any;
  selected: 'CCuser';
  current_date = new Date();

  constructor(
    public dialogRef: MatDialogRef<EditUserDialog>,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData1) { }


  editUser() {
    const data = {
      prev_email: this.data.prev_email,
      email: this.email,
      password: this.password,
      type: this.selected,
      firstname: this.name,
    };
    // console.log(data);
    this.http.post('https://digitaltag.tag8.in/api/editCCuser/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
      this.snackBar.open(' User Updated  ', 'Undo', {
        duration: 3000
      });
      location.reload();
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}