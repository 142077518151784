import { Component, OnInit } from '@angular/core';
import { AppService } from './services/app.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-user-admin-view',
  templateUrl: './user-admin-view.component.html',
  styleUrls: ['./user-admin-view.component.scss']
})
export class UserAdminViewComponent implements OnInit {

  
  
  constructor(private appService: AppService,private route: ActivatedRoute) {}
  //Getting the value from Dashboard Component
  message:string;
  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

 

  ngOnInit() {
    
  }

}
