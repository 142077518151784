import { Component, OnInit, ViewChild, AfterViewInit, Inject, HostListener } from '@angular/core';
import { UserService } from '../../../core/user.service';
import { AuthService } from '../../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers } from '@angular/http';
import { Subject } from 'rxjs';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Users } from './users.model';
import { Found } from './found.model';
import { Alerts } from './alert.model';
import { Uninstall } from './uninstall.model';
import { setEmitFlags } from 'typescript';
import { RequestOptions, ResponseContentType } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

//Global Variable to Incremented within onPaginateChange()
var start = 0;

export interface DialogData {
  imei: string;
  membership_expiry_date: string;

}



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AdmDashboardComponent implements OnInit {


  //Coulmns
  dispalyedColumns = ['firstname', 'email', 'phone', 'status', 'createdOn', 'view'];
  dispalyedColumns2 = ['name', 'geolocation', 'timestamp'];
  dispalyedColumns3 = ['name', 'geolocation', 'phone', 'status', 'timestamp'];
  dispalyedColumns4 = ['name', 'phone', 'model', 'last_location', 'timestamp'];

  //Mapping Data with the Interface
  dataSource = new MatTableDataSource<Users>();

  dataSource2 = new MatTableDataSource<Found>();
  dataSource3 = new MatTableDataSource<Alerts>();
  dataSource4 = new MatTableDataSource<Uninstall>();

  //Adding Refrence to the total Registration table
  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;

  @ViewChild('table2', { read: MatSort }) secondDataSort: MatSort;
  @ViewChild('page2', { read: MatPaginator }) secondDataPaginator: MatPaginator;

  @ViewChild('table3', { read: MatSort }) thirdDataSort: MatSort;
  @ViewChild('page3', { read: MatPaginator }) thirdDataPaginator: MatPaginator;

  @ViewChild('table4', { read: MatSort }) fourthDataSort: MatSort;
  @ViewChild('page4', { read: MatPaginator }) fourthDataPaginator: MatPaginator;

  imei;
  membership_expiry_date;
  image;
  newForm = false;
  data1;
  places;
  details;
  counts;
  found;
  alert;
  uninstall;
  img;
  thirdarr = [];
  hide = 'block';
  show = 'none';
  alerts = 'none';
  uni = 'none';

  usersearch;


  public origin: any;
  public destination: any;

  uid;


  profileForm: FormGroup;


  //Every useful package needs to have a Constructor 
  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    public http: HttpClient,
    /* public uidService: UseriddetailsService, */
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService

  ) {

  }

  ngOnInit(): void {

    //Method to display the Spinner
    this.spinner.show();
    //Passing an Empty Body
    var body = {};
    //Using the Http refrence to create invoke the api
    const obs = this.http.post('/api/getBatchUsers', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    //Subscribing the to get the respnse
    obs.subscribe((response: any) => {


      // const data = [];
      console.log(response);
      // data.push(response);

      // this.data1 = data;
      // console.log(this.data1);

      //Assiging the response data to Datasource of the Datatable
      this.dataSource.data = response.data;
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);

    });

    /*const found = this.http.get('https://digitaltag.tag8.in/api/foundDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    found.subscribe((response: any) => {
      this.dataSource2.data = response;
    });*/


    /*const alert = this.http.get('https://digitaltag.tag8.in/api/alertDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    alert.subscribe((response: any) => {
      console.log(response);
      this.dataSource3.data = response;
    });*/

    /*const unistallcount = this.http.get('https://digitaltag.tag8.in/api/uninstallUser/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    unistallcount.subscribe((response: any) => {
      this.dataSource4.data = response;
      console.log(response);
    });*/



    const usercount = this.http.get('https://digitaltag.tag8.in/api/userCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    usercount.subscribe((response) => {

      sessionStorage.removeItem('userscount');

      this.counts = response;

      sessionStorage.setItem('userscount', this.counts);


      console.log(this.counts);
    });

    const rtfoundcount = this.http.get('https://digitaltag.tag8.in/api/foundCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    rtfoundcount.subscribe((response) => {
      this.found = response;
    });

    const alertcount = this.http.get('https://digitaltag.tag8.in/api/alertCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    alertcount.subscribe((response) => {
      this.alert = response;
    });

    const uninstallcount = this.http.get('https://digitaltag.tag8.in/api/unistallCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    uninstallcount.subscribe((response) => {
      this.uninstall = response;
    });
  }

  onPaginateChange() {

    start += 100;
    var body = {
      start: start
    }
    const obs = this.http.post('https://digitaltag.tag8.in/api/getBatchUsers', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {
      this.data1 = this.dataSource.data;
      //  console.log(response.data.length);
      //  this.data1.push(response.data);
      //  console.log(this.data1);
      for (var i = 0; i < response.data.length; i++) {
        this.data1.push(response.data[i]);
      }
      console.log(this.data1.length);
      this.dataSource.data = this.data1;
    });
  }

  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required]
    });
  }

  save(value) {
    this.userService.updateCurrentUser(value)
      .then(res => {
        console.log(res);
      }, err => console.log(err));
  }

  setUid = function (user) {
    //this.uidService.getuid(user);
    localStorage.setItem('userid', user);
  };

  getRegistered() {
    //Method to Display and Hide the Total Registred table
    this.hide = 'block';
    this.show = 'none';
    this.alerts = 'none';
    this.uni = 'none';
  };

  displayAlerts = function () {
    const alert = this.http.get('https://digitaltag.tag8.in/api/alertDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    alert.subscribe((response: any) => {
      console.log(response);
      this.dataSource3.data = response;
    });
    this.alerts = 'none';
    this.show = 'block';
    this.hide = 'none';
    this.uni = 'none';
  };

  displayFound = function () {
    const found = this.http.get('https://digitaltag.tag8.in/api/foundDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    found.subscribe((response: any) => {
      this.dataSource2.data = response;
    });
    this.alerts = 'block';
    this.hide = 'none';
    this.show = 'none';
    this.uni = 'none';
  };

  displayUninstall = function () {
    const unistallcount = this.http.get('https://digitaltag.tag8.in/api/uninstallDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    unistallcount.subscribe((response: any) => {
      this.dataSource4.data = response;
      console.log(response);
    });
    this.alerts = 'none';
    this.uni = 'block';
    this.hide = 'none';
    this.show = 'none';
  };

  doFilter(filterValue, search) {
    //Search value enter in the textbox for searching
    console.log(search);
    //Adding the search varaible inside the body object
    var body = {
      search: search
    }
    //Invoking the api with passing the body
    const obs = this.http.post('/api/getBatchUsers', body,
      {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
      });

    obs.subscribe((response: any) => {
      //assigning the value to table DataSource
      this.dataSource.data = response.data;

    });


  }

  doFilter1(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  doFilter2(filterValue: string) {
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

  doFilter3(filterValue: string) {
    this.dataSource4.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    //  this.dataSource.sort = this.sort;
    //  this.dataSource.paginator  = this.paginator;
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;
    this.dataSource2.sort = this.secondDataSort;
    this.dataSource2.paginator = this.secondDataPaginator;
    this.dataSource3.sort = this.thirdDataSort;
    this.dataSource3.paginator = this.thirdDataPaginator;
    this.dataSource4.sort = this.fourthDataSort;
    this.dataSource4.paginator = this.fourthDataPaginator;


  }

  //Function to redirect to UserView Page
  viewUserPanel(uid) {
    this.router.navigate([]).then(result => { window.open('#/UserAdminView', '_blank'); });
    // window.open('#/UserAdminView')
    sessionStorage.setItem('viewUser', uid);
    sessionStorage.setItem('userType', 'admin');

  }




  openDialog(uid): void {

    localStorage.setItem('user_id', uid);

    const dialogRef = this.dialog.open(AddPremiumDialog, {
      width: '750px',
      data: {
        imei: this.imei,
        membership_expiry_date: this.membership_expiry_date
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      localStorage.removeItem('user_id');
    });
  }


  createReport() {
    const dialogRef = this.dialog.open(GenerateReport, {
      width: '750px',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  createAlertReport() {
    const dialogRef = this.dialog.open(AlertReport, {
      width: '750px',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



  createFoundReport() {
    const dialogRef = this.dialog.open(FoundReport, {
      width: '750px',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  createUnistallReport() {
    const dialogRef = this.dialog.open(UninstallReport, {
      width: '750px',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }


}


@Component({
  selector: 'addpremium-dialog',
  templateUrl: 'addpremimum-dialog.html',
  styleUrls: ['./addpremimum-dialog.css']
})
export class AddPremiumDialog implements OnInit {


  userdevices: any;

  constructor(
    public dialogRef: MatDialogRef<AddPremiumDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe) { }

  labelPosition = 'after';

  flag = 'false';
  extend = 'false';
  valid = 'true';
  validupto;



  ngOnInit() {

    const obj = this.http.get('https://digitaltag.tag8.in/api/getDetails/' + localStorage.getItem('user_id'), {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obj.subscribe((response: any) => {
      this.userdevices = response.data;
      console.log(this.userdevices[0].subscription_plan);


      if (this.userdevices[0].subscription_plan === 'PAID') {
        this.flag = 'true';
      } else if (this.userdevices[0].subscription_plan === 'FREE') {
        this.flag = 'false';
      }


    });



  }



  extendValidity(imei, uid) {


    this.extend = 'true';

    console.log(this.validupto);
    // console.log(this.datePipe.transform(this.validupto, ' MMM d, y, h:mm:ss a' ) );
    const dt = this.datePipe.transform(this.validupto, ' MMM d, y, h:mm:ss a');
    const addedPremium = this.http.get('https://digitaltag.tag8.in/api/activateUser/' + imei + '&' + dt + '&' + uid, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    addedPremium.subscribe((respone) => {
      this.snackBar.open('Added Premium Member', '200 OK', {
        duration: 3000
      });
      //Method to refresh
      window.location.reload();
    });

  }







  activatePremium(device_id, uid) {

    console.log(device_id);

    console.log(this.validupto);
    const dt = this.datePipe.transform(this.validupto, ' MMM d, y, h:mm:ss a');
    const addedPremium = this.http.get('https://digitaltag.tag8.in/api/activateUser/' + device_id + '&' + dt + '&' + uid, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    addedPremium.subscribe((respone) => {
      this.snackBar.open('Added Premium Member', 'Undo', {
        duration: 3000
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



}


@Component({
  selector: 'generate-dialog',
  templateUrl: 'generate-dialog.html',
  styleUrls: ['./generate-dialog.css']
})
export class GenerateReport implements OnInit {


  fileUrl;
  filename = 'User_Reg_Master_Data_1562821599513.xlsx';
  startDate;
  endDate;
  constructor(
    public dialogRef: MatDialogRef<GenerateReport>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {


  }

  downLoadfile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    saveAs(blob, 'report.xlsx');
  }

  downloadFile(url, fname) {

    console.log(url);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.get(url, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => this.downLoadfile(response, "application/ms-excel"));
  }
  //Function to Download Registered Report

  downloadReport() {
    const start = this.startDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '00:30');
    const fin = this.endDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '13:00');

    const fd = this.datePipe.transform(start, ' MMM d, y, h:mm:ss a');
    const ld = this.datePipe.transform(fin, ' MMM d, y, h:mm:ss a');


    const registerReport = this.http.get('https://digitaltag.tag8.in/api/reportsregister/' + fd + '&' + ld, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    registerReport.subscribe(async (response: any) => {

      this.snackBar.open('Generating Report...', '200 OK', {
        duration: 3000
      });

      this.fileUrl = await response.filepath;
      this.downloadFile(this.fileUrl, this.filename);

    });

  }

}



@Component({
  selector: 'alertreport-dialog',
  templateUrl: 'alertreport-dialog.html',
  styleUrls: ['./generate-dialog.css']
})
export class AlertReport implements OnInit {
  fileUrl;
  filename = 'User_Reg_Master_Data_1562821599513.xlsx';
  startDate;
  endDate;
  constructor(
    public dialogRef: MatDialogRef<AlertReport>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

  downLoadfile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    saveAs(blob, 'alertreport.xlsx');
  }

  downloadFile(url, fname) {
    console.log(url);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.get(url, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => this.downLoadfile(response, "application/ms-excel"));


  }
  //Function to Download Registered Report

  downloadAlertReport() {
    const start = this.startDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '00:30');
    const fin = this.endDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '13:00');

    const fd = this.datePipe.transform(start, ' MMM d, y, h:mm:ss a');
    const ld = this.datePipe.transform(fin, ' MMM d, y, h:mm:ss a');


    const registerReport = this.http.get('https://digitaltag.tag8.in/api/reportsalerts/' + fd + '&' + ld, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    registerReport.subscribe(async (response: any) => {

      this.snackBar.open('Generating Report...', '200 OK', {
        duration: 3000
      });
      this.fileUrl = await response.filepath;
      this.downloadFile(this.fileUrl, this.filename);

    });

  }

}



@Component({
  selector: 'alertfound-dialog',
  templateUrl: 'alertfound-dialog.html',
  styleUrls: ['./generate-dialog.css']
})
export class FoundReport implements OnInit {
  fileUrl;
  filename = 'User_Found_Master_Data_1562821599513.xlsx';
  startDate;
  endDate;
  constructor(
    public dialogRef: MatDialogRef<FoundReport>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

  downLoadfile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    saveAs(blob, 'foundreport.xlsx');
  }

  downloadFile(url, fname) {
    console.log(url);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.get(url, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => this.downLoadfile(response, "application/ms-excel"));


  }
  //Function to Download Registered Report

  downloadFoundReport() {
    const start = this.startDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '00:30');
    const fin = this.endDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '13:00');

    const fd = this.datePipe.transform(start, ' MMM d, y, h:mm:ss a');
    const ld = this.datePipe.transform(fin, ' MMM d, y, h:mm:ss a');


    const registerReport = this.http.get('https://digitaltag.tag8.in/api/reportfound/' + fd + '&' + ld, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    registerReport.subscribe(async (response: any) => {


      this.snackBar.open('Generating Report...', '200 OK', {
        duration: 3000
      });

      this.fileUrl = await response.filepath;
      this.downloadFile(this.fileUrl, this.filename);

    });

  }

}



@Component({
  selector: 'unistall-dialog',
  templateUrl: 'unistall-dialog.html',
  styleUrls: ['./generate-dialog.css']
})
export class UninstallReport implements OnInit {
  fileUrl;
  filename = 'User_Unistall_Master_Data_1562821599513.xlsx';
  startDate;
  endDate;
  constructor(
    public dialogRef: MatDialogRef<UninstallReport>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

  downLoadfile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    saveAs(blob, 'unistallreport.xlsx');
  }

  downloadFile(url, fname) {
    console.log(url);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.get(url, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => this.downLoadfile(response, "application/ms-excel"));


  }
  //Function to Download Registered Report

  downloadUnistallReport() {
    const start = this.startDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '00:30');
    const fin = this.endDate.toString().replace('00:00:00 GMT+0530 (India Standard Time)', '13:00');

    const fd = this.datePipe.transform(start, ' MMM d, y, h:mm:ss a');
    const ld = this.datePipe.transform(fin, ' MMM d, y, h:mm:ss a');


    const registerReport = this.http.get('http://digitaltag.tag8.in/api/reportsuninstall/' + fd + '&' + ld, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    registerReport.subscribe(async (response: any) => {
      this.snackBar.open('Generating Report...', '200 OK', {
        duration: 3000
      });
      this.fileUrl = await response.filepath;
      this.downloadFile(this.fileUrl, this.filename);

    });
  }
}
