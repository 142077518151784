import { ViewChild, Component, OnInit } from '@angular/core';
import { Tasks } from './task.model';
import { Viewed } from './view.model';
import { ActivatedRoute , Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tasklist',
  templateUrl: './tasklist.component.html',
  styleUrls: ['./tasklist.component.scss']
})
export class TasklistComponent implements OnInit {


  show = 'block';
  alerts = 'none';
  uni = 'none';
  data1;
  _albums;
  totalalerts;
  totalviews;
  totalpending;
  viewsdata = [];
  pendingdata = [];
  checked = false;
  dataSource = new MatTableDataSource<Tasks>();
  dataSource1 = new MatTableDataSource<Viewed>();
  dataSource2 = new MatTableDataSource<Viewed>();

  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;

  @ViewChild('table2', { read: MatSort }) seccondDataSort: MatSort;
  @ViewChild('page2', { read: MatPaginator }) secondDataPaginator: MatPaginator;


  @ViewChild('table3', { read: MatSort }) thirdDataSort: MatSort;
  @ViewChild('page3', { read: MatPaginator }) thirdDataPaginator: MatPaginator;


  dispalyedColumns = ['action', 'name' , 'imei', 'status', 'geolocation', 'time', 'view'];
  dispalyedColumns1 = [ 'name' , 'imei', 'status', 'geolocation', 'time', 'view'];



  constructor(public http: HttpClient,private toastr: ToastrService,private router: Router , private spinner: NgxSpinnerService) { }


  displayLogs = function () {
    this.alerts = 'none';
    this.show = 'block';
    this.hide = 'none';
    this.uni = 'none';
  };


  displayViewed = function () {
    this.alerts = 'block';
    this.hide = 'none';
    this.show = 'none';
    this.uni = 'none';
  }

  displayPending = function () {
    this.alerts = 'none';
    this.uni = 'block';
    this.hide = 'none';
    this.show = 'none';
  }

  ngOnInit() {

    this.getTasksData();
    this.getViewedData();
    this.getPendingtasks();

    this.spinner.show();
    setTimeout(() => {
        this.spinner.hide();
    }, 5000);


    setTimeout(() => {
      this.getPendingtasks();
    }, 10000);

    setInterval(() => {
      this._albums = [];

      this.getTasksData();
      this.getViewedData();

      this.toastr.success('Updated Data!', 'Successfully!');

    }, 500000);
  }




  getViewedData() {

    const viewed = this.http.get('https://digitaltag.tag8.in/api/viewedtask', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    viewed.subscribe((response: any) => {

      this.dataSource1.data = response.data;
      // console.log(response.data);
      this.viewsdata = response.data;
      this.totalviews = response.data.length;
      
    });


  }

  getTasksData() {
    this._albums = [];
    const obs = this.http.get('https://digitaltag.tag8.in/api/getTasks', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {

      let audio = new Audio();
      audio.src = "../../assets/beep-01.wav";
      audio.load();
      audio.play();

      // console.log(response.data);

      for (let i = 0; i < response.data.length; i++) {

        if (response.data[i].images.length > 0) {
          for (let k = 0; k < response.data[i].images.length; k++) {

            //console.log(response.data[i].location);

            const str = response.data[i].images[k].key;


            const getimei = str.indexOf('_') + 1;
            const getimeival = str.indexOf('_', getimei);
            const imeival = str.substring(getimei, getimeival);
            const start_pos = str.indexOf('_') + 1;
            const end_pos = str.indexOf('_', start_pos);
            const result1 = str.substring(start_pos);
            const timest = result1.lastIndexOf('_');
            const time = result1.substr(timest + 1);
            const st = result1.indexOf('_') + 1;
            const ep = result1.indexOf('_', st);
            const status = result1.substring(st, ep);

            if (response.data[i].location.imei === imeival && response.data[i].location.timestamp === time && response.data[i].location.status === status) {

              const src = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + response.data[i].images[k].key;
              const album = {
                src: src,
                time: response.data[i].location.timestamp,
                status: status,
                name: response.data[i].location.name,
                imei: response.data[i].location.imei,
                uid: response.data[i].location.uid,
                latitude: response.data[i].location.latitude,
                longitude: response.data[i].location.longitude,
                action_taken: response.data[i].location.action_taken
              };

              this._albums.push(album);

              this._albums.sort((val1: any, val2: any) => { return +new Date(val2.time) - +new Date(val1.time) })

              // console.log(this._albums);

              this.totalalerts = this._albums.length;
              this.dataSource.data = this._albums;
              //   this.pendingdata = this._albums.filter(function(obj) { return this.viewsdata.indexOf(obj) == -1; });


              break;
            }
          }
        }
      }

    });
  }


  addViews(uid, time, src) {

    // console.log(uid);
    // console.log(time);
    var body = {
      uid: uid,
      timestamp: time,
      src: src
    }

    this.http.post('https://digitaltag.tag8.in/api/updatetask', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response: any) => {
      // console.log(response);
      this.toastr.success('Created Ticket !', 'Successfully!');
      setTimeout(()=>{
        window.location.reload();
      },1000)


    });
  }


   //Function to redirect to UserView Page
   viewUserPanel(uid) {
    this.router.navigate(['/UserAdminView']);
    sessionStorage.setItem('viewUser',uid);
    sessionStorage.setItem('userType','ccuser');

  }



  getPendingtasks() {

    var temp = [];
    
      for(var i in this._albums) {
      if(this.viewsdata.indexOf(this._albums[i]) === -1) temp.push(this._albums[i]);
      }

      this.totalpending = temp.length; 
      console.log(temp);

      this.dataSource2.data =  temp;



    // const pending = this.http.get('https://digitaltag.tag8.in/api/api/pendingtask', {
    //   headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    // });

    // pending.subscribe((response: any) => {

    //   console.log(response.data);

    //   for (let i = 0; i < response.data.length; i++) {

    //     if (response.data[i].images.length > 0) {
    //       for (let k = 0; k < response.data[i].images.length; k++) {

    //         //console.log(response.data[i].location);

    //         const str = response.data[i].images[k].key;


    //         const getimei = str.indexOf('_') + 1;
    //         const getimeival = str.indexOf('_', getimei);
    //         const imeival = str.substring(getimei, getimeival);
    //         const start_pos = str.indexOf('_') + 1;
    //         const end_pos = str.indexOf('_', start_pos);
    //         const result1 = str.substring(start_pos);
    //         const timest = result1.lastIndexOf('_');
    //         const time = result1.substr(timest + 1);
    //         const st = result1.indexOf('_') + 1;
    //         const ep = result1.indexOf('_', st);
    //         const status = result1.substring(st, ep);

    //         if (response.data[i].location.imei === imeival && response.data[i].location.timestamp === time && response.data[i].location.status === status) {

    //           const src = 'https://s3.ap-south-1.amazonaws.com/digitaltag/' + response.data[i].images[k].key;
    //           const album = {
    //             src: src,
    //             time: response.data[i].location.timestamp,
    //             status: status,
    //             imei: response.data[i].location.imei,
    //             uid: response.data[i].location.uid,
    //             latitude: response.data[i].location.latitude,
    //             longitude: response.data[i].location.longitude,
    //             action_taken: response.data[i].location.action_taken
    //           };

    //           this.pendingdata.push(album);

    //           this.pendingdata.sort((val1: any, val2: any) => { return +new Date(val2.time) - +new Date(val1.time) })

    //           console.log(this.pendingdata);

    //           this.totalpending = this.pendingdata.length;
    //           this.dataSource2.data = this.pendingdata;
    //           break;
    //         }
    //       }
    //     }
    //   }

    // });


  }






  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  doFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  doFilter2(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }



  ngAfterViewInit() {
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;
    this.dataSource1.sort = this.seccondDataSort;
    this.dataSource1.paginator = this.secondDataPaginator;
    this.dataSource2.sort = this.thirdDataSort;
    this.dataSource2.paginator = this.thirdDataPaginator;

  }







}
