export class UseriddetailsService {
    uid;
    token;
    getuid(userid: string) {
        this.uid = userid;
    }

    gettoken(jwttoken: string) {
        this.token = jwttoken;
    }

}
