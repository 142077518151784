import { Component, OnInit  , ViewChild , AfterViewInit , Inject} from '@angular/core';
import { MatTableDataSource , MatSort , MatPaginator, throwMatDialogContentAlreadyAttachedError } from '@angular/material';

import { AuthService } from '../core/auth.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {  FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import {MatSnackBar} from '@angular/material';
import { Userlist } from './user-list.model';



export interface DialogData {
    email: string;
    password: string;
    type: string;
}


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit ,  AfterViewInit {

  email:  any;
  password: any;
  type: any;
 
  dispalyedColumns = [  'email' , 'firstname', 'type'  , 'createdOn'];
  dataSource = new  MatTableDataSource<Userlist>();


  @ViewChild('table1', {read: MatSort}) firstDataSort: MatSort;
  @ViewChild('page1', {read: MatPaginator}) firstDataPaginator: MatPaginator;

  constructor(
    public authService: AuthService,
    private location: Location,
    public  http: HttpClient ,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    const userlist = this.http.get('https://digitaltag.tag8.in/api/getCCuser' ,  {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
    } );

    userlist.subscribe((response: any) => {
      console.log(response);
      this.dataSource.data = response;
    });

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewDialog, {
      width: '750px',
      data: {email : this.email,
          password: this.password,
          type: this.type
         }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });

  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase( );
  }

  logout() {
    this.authService.doLogout()
    .then((res) => {
      sessionStorage.removeItem('token');
      console.log('tokenremoved');
      this.location.back();
    }, (error) => {
      console.log('Logout error', error);
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator  = this.firstDataPaginator;
  }

}


@Component({
  selector: 'dialog-overview-dialog',
  templateUrl: 'dialog-overview-dialog.html',
  styleUrls: ['./dialog-overview-dialog.css']
})
export class DialogOverviewDialog {

  email: any;
  password: any;
  name: any;
  selected : 'CCuser';
  current_date = new Date();

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ,
    public  http: HttpClient ,
    public snackBar: MatSnackBar) {}





    addUser() {

      console.log(this.name);

        const data = {
                email : this.email ,
                password : this.password,
                type : this.selected ,
                firstname: this.name,
                createdOn: this.current_date
              };
console.log(this.selected);

      this.http.post('https://digitaltag.tag8.in/api/addCCuser/' , data  ,  {
        headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
      }).subscribe((response) => {
        console.log(response);
        this.snackBar.open(' User Created  ', 'Undo', {
          duration: 3000
        });
        location.reload();
       });



      }


  onNoClick(): void {
    this.dialogRef.close();
  }



}
