import { Component, OnInit } from '@angular/core';
declare var gtag;
@Component({
  selector: 'app-pricings',
  templateUrl: './pricings.component.html',
  styleUrls: ['./pricings.component.css']
})
export class PricingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getNGLanding() {
    window.open('https://bit.ly/3qgt9um');
  //   window.location.href = "assets/Digital_Tag_Find_My_Phone.apk";
  //   gtag('event', 'conversion', {
  //     'send_to': 'AW-828190185/Fsq5CO-f1OMCEOnb9IoD'
  // });
  }

}
