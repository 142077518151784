import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';  //md Bootstrap design for angular
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from '../app.routes';  // Main Routes Import
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';

//Material Module start
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { NgxSpinnerModule } from 'ngx-spinner';

import { UserAdminComponent } from './user-admin.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';

import { AdmDashboardComponent } from './pages/dashboard/dashboard.component';
import { ItemListComponent } from './pages/ItemsList/Itemslist.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { GeoLocationComponent } from './pages/maps/maps.component';
import { MembersListComponent } from './pages/member-list/member-list.component';
import { AddUserComponent } from './pages/add-user/add-user.component';

import { CreateUserDialog } from './pages/add-user/add-user.component';
import { EditUserDialog } from './pages/add-user/add-user.component';
import { AddCouponsDialog } from './pages/coupons/coupons.component';
import { AddPremiumDialog  } from './pages/dashboard/dashboard.component';
import { GenerateReport  } from './pages/dashboard/dashboard.component';
import { AlertReport  } from './pages/dashboard/dashboard.component';
import { FoundReport  } from './pages/dashboard/dashboard.component';
import { UninstallReport  } from './pages/dashboard/dashboard.component';

import {  NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import {
  AgmCoreModule
} from '@agm/core';

@NgModule({
  declarations: [
    UserAdminComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    FullscreenComponent,
    AdmDashboardComponent,
    ItemListComponent,
    CouponsComponent,
    GeoLocationComponent,
    MembersListComponent,
    AddUserComponent,
    CreateUserDialog,
    EditUserDialog,
    AddCouponsDialog,
    AddPremiumDialog,
    GenerateReport,
    AlertReport,
    FoundReport,
    UninstallReport
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: true }),  // Main Routes call
    CommonModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    ToastrModule.forRoot(),
    MatButtonModule,
    MDBBootstrapModule.forRoot(),
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatInputModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    FileUploadModule,
    MatTooltipModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'AIzaSyBxzri4-_Mg7d7X1W5wodq6SPnxWSGdNBQ'
    }),
    ],
  providers: [],
  bootstrap: [UserAdminComponent],
  entryComponents: [CreateUserDialog, EditUserDialog, AddCouponsDialog, AddPremiumDialog,GenerateReport,AlertReport,FoundReport,UninstallReport],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class UserAdmModule { }
