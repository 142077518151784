import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-reportfound',
  templateUrl: './reportfound.component.html',
  styleUrls: ['./reportfound.component.scss']
})
export class ReportfoundComponent implements OnInit {

  ownerid;
  finder_name;
  phone_number;


  finder = new FormControl('', [Validators.required]);
  phone = new FormControl('', [Validators.required]);


  getErrorMessage() {
    this.finder.hasError('required') ? 'Please enter your Name' :
      '';
    this.phone.hasError('required') ? 'Please enter your PhoneNumber' :
      '';

  }

  constructor(private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public http: HttpClient, ) { }

  ngOnInit() {
    this.ownerid = this.route.snapshot.paramMap.get('oid');
    console.log(this.ownerid);
  }

  fileReport() {
    const body = {
      finder_name: this.finder_name,
      phone: this.phone,
      ownerid: this.ownerid,

    };

    const obj = this.http.post('https://digitaltag.tag8.in/api/reportfound', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obj.subscribe((response: any) => {

      if (response.data == 'Report Created') {
        console.log(response);
        this.snackBar.open('Report Created', 'Ok', {
          duration: 5000
        });
      }
      else if (response.data == 'Invalid') {
        console.log(response);
        this.snackBar.open('Invalid Parameters', 'Ok', {
          duration: 5000
        });
      }
      setTimeout(() => {
        window.location.href = 'https://digitaltag.tag8.in/#/home'
      }, 4000);


    });
  }


  clear() {
    this.finder_name = '';
    this.phone_number = '';
  }
}
