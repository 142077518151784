import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../core/auth.service';
import { Location } from '@angular/common';
import { UseriddetailsService } from '../useriddetails.service';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.css']
})
export class MapviewComponent implements OnInit {
  zoom: number =  2;
  lat: number = 19.102512;
  lng: number = 72.8320677;
  places;
  icon = {
    url: './assets/map icon red.png',
    scaledSize: {
      width: 20,
      height: 20
    }
};
icon2 = {
  url: './assets/map icongreen.png',
  scaledSize: {
    width: 20,
    height: 20
  }
};

  constructor( public  http: HttpClient , private uidService: UseriddetailsService  ,
         public authService: AuthService , private location: Location ) { }

  ngOnInit() {
    const loc = this.http.get('https://digitaltag.tag8.in/api/locationdetails' , {
      headers: {'Authorization': 'Bearer ' + this.uidService.token}
    });

    loc.subscribe((response: any ) => {
      this.places  =  response.data;
      console.log(response);
  
  });

}
logout() {
  this.authService.doLogout()
  .then((res) => {
    sessionStorage.removeItem('token');
    this.location.back();
  }, (error) => {
    console.log('Logout error', error);
  });
}
}
