import { Component, OnInit, ViewChild, AfterViewInit, Inject, HostListener } from '@angular/core';
import { UserService } from '../../../core/user.service';
import { AuthService } from '../../../core/auth.service';
import { ActivatedRoute , Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Users } from './users.model';
import { Found } from './found.model';
import { Alerts } from './alert.model';
import { Uninstall } from './uninstall.model';

var start = 0;

export interface DialogData {
  imei: string;
  membership_expiry_date: string;

}



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class CcDashboardComponent implements OnInit {

  dispalyedColumns = ['firstname', 'email', 'phone', 'status' , 'view'];
  dispalyedColumns2 = ['name', 'geolocation', 'imei', 'timestamp'];
  dispalyedColumns3 = ['name', 'geolocation', 'imei', 'timestamp'];
  dispalyedColumns4 = ['name', 'email', 'phone', 'model', 'last_location', 'timestamp'];

  dataSource = new MatTableDataSource<Users>();
  dataSource2 = new MatTableDataSource<Found>();
  dataSource3 = new MatTableDataSource<Alerts>();
  dataSource4 = new MatTableDataSource<Uninstall>();


  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;

  @ViewChild('table2', { read: MatSort }) secondDataSort: MatSort;
  @ViewChild('page2', { read: MatPaginator }) secondDataPaginator: MatPaginator;

  @ViewChild('table3', { read: MatSort }) thirdDataSort: MatSort;
  @ViewChild('page3', { read: MatPaginator }) thirdDataPaginator: MatPaginator;

  @ViewChild('table4', { read: MatSort }) fourthDataSort: MatSort;
  @ViewChild('page4', { read: MatPaginator }) fourthDataPaginator: MatPaginator;

  imei;
  membership_expiry_date;
  image;
  newForm = false;
  data1;
  places;
  details;
  counts;
  found;
  alert;
  uninstall;
  img;
  thirdarr = [];
  hide = 'block';
  show = 'none';
  alerts = 'none';
  uni = 'none';


  public origin: any;
  public destination: any;

  uid;


  profileForm: FormGroup;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    public http: HttpClient,
    /* public uidService: UseriddetailsService, */
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
 
    //Sending an empty object
    var body = {   
    };


    const obs = this.http.post('https://digitaltag.tag8.in/api/getBatchUsers', body , {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {
      const data = [];
      console.log(response);
      data.push(response);

      this.data1 = data;
      console.log(this.data1);
      this.dataSource.data = response.data;

    });

    const found = this.http.get('https://digitaltag.tag8.in/api/foundDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    found.subscribe((response: any) => {
      this.dataSource2.data = response;
    });


    const alert = this.http.get('https://digitaltag.tag8.in/api/alertDetails/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    alert.subscribe((response: any) => {
      console.log(response);
      this.dataSource3.data = response;
    });

    const unistallcount = this.http.get('https://digitaltag.tag8.in/api/uninstallUser/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    unistallcount.subscribe((response: any) => {
      this.dataSource4.data = response;
      console.log(response);
    });



    const usercount = this.http.get('https://digitaltag.tag8.in/api/userCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    usercount.subscribe((response) => {
      this.counts = response;
      console.log(this.counts);
    });

    const rtfoundcount = this.http.get('https://digitaltag.tag8.in/api/foundCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    rtfoundcount.subscribe((response) => {
      this.found = response;
    });

    const alertcount = this.http.get('https://digitaltag.tag8.in/api/alertCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    alertcount.subscribe((response) => {
      this.alert = response;
    });

    const uninstallcount = this.http.get('https://digitaltag.tag8.in/api/unistallCount/', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    uninstallcount.subscribe((response) => {
      this.uninstall = response;
    });
  }

  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required]
    });
  }

  save(value) {
    this.userService.updateCurrentUser(value)
      .then(res => {
        console.log(res);
      }, err => console.log(err));
  }

  setUid = function (user) {
    //this.uidService.getuid(user);
    localStorage.setItem('userid', user);
  };

  getRegistered() {
    this.hide = 'block';
    this.show = 'none';
    this.alerts = 'none';
    this.uni = 'none';
  };

  displayAlerts = function () {
    this.alerts = 'none';
    this.show = 'block';
    this.hide = 'none';
    this.uni = 'none';
  };

  displayFound = function () {
    this.alerts = 'block';
    this.hide = 'none';
    this.show = 'none';
    this.uni = 'none';
  };
  onPaginateChange() {

    start += 100;
    var body = {
      start:start
    }
    const obs = this.http.post('https://digitaltag.tag8.in/api/getBatchUsers', body , {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {
       this.data1 = this.dataSource.data;    
      //  console.log(response.data.length);
      //  this.data1.push(response.data);
      //  console.log(this.data1);
        for(var i=0;i<response.data.length;i++) {
          this.data1.push(response.data[i]);
        }
        console.log(this.data1.length);
       this.dataSource.data = this.data1;
    });
  }

  displayUninstall = function () {
    this.alerts = 'none';
    this.uni = 'block';
    this.hide = 'none';
    this.show = 'none';
  };

  doFilter(filterValue,search) {
    
  
      console.log(search);
      var body = {
        search :search
      }
    
    const obs = this.http.post('https://digitaltag.tag8.in/api/getBatchUsers' , body ,
     {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {


      this.dataSource.data = response.data;

    });


  }

  doFilter1(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  doFilter2(filterValue: string) {
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

  doFilter3(filterValue: string) {
    this.dataSource4.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    //  this.dataSource.sort = this.sort;
    //  this.dataSource.paginator  = this.paginator;
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;
    this.dataSource2.sort = this.secondDataSort;
    this.dataSource2.paginator = this.secondDataPaginator;
    this.dataSource3.sort = this.thirdDataSort;
    this.dataSource3.paginator = this.thirdDataPaginator;
    this.dataSource4.sort = this.fourthDataSort;
    this.dataSource4.paginator = this.fourthDataPaginator;


  }

  //Function to redirect to UserView Page
  viewUserPanel(uid) {
    this.router.navigate(['/UserAdminView']);
    sessionStorage.setItem('viewUser',uid);
    sessionStorage.setItem('userType','ccuser');
  }


  openDialog(uid): void {

    localStorage.setItem('user_id', uid);

    const dialogRef = this.dialog.open(AddPremiumDialog, {
      width: '750px',
      data: {
        imei: this.imei,
        membership_expiry_date: this.membership_expiry_date
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      localStorage.removeItem('user_id');

    });


  }
}


@Component({
  selector: 'addpremium-dialog',
  templateUrl: 'addpremimum-dialog.html',
  styleUrls: ['./addpremimum-dialog.css']
})
export class AddPremiumDialog implements OnInit {


  userdevices: any;

  constructor(
    public dialogRef: MatDialogRef<AddPremiumDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe) { }

  labelPosition = 'after';

  flag = 'false';
  extend = 'false';
  valid = 'true';
  validupto;



  ngOnInit() {

    const obj = this.http.get('https://digitaltag.tag8.in/api/getDetails/' + localStorage.getItem('user_id'), {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obj.subscribe((response: any) => {
      this.userdevices = response.data;
      console.log(this.userdevices[0].subscription_plan);


      if (this.userdevices[0].subscription_plan === 'PAID') {
        this.flag = 'true';
      } else if (this.userdevices[0].subscription_plan === 'FREE') {
        this.flag = 'false';
      }


    });



  }



  extendValidity(imei, uid) {

    this.extend = 'true';

    console.log(this.validupto);
    // console.log(this.datePipe.transform(this.validupto, ' MMM d, y, h:mm:ss a' ) );
    const dt = this.datePipe.transform(this.validupto, ' MMM d, y, h:mm:ss a');
    const addedPremium = this.http.get('https://digitaltag.tag8.in/api/activateUser/' + imei + '&' + dt + '&' + uid, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    addedPremium.subscribe((respone) => {
      this.snackBar.open('Added Premium Member', 'Undo', {
        duration: 3000
      });
      //Method to refresh
      window.location.reload();
    }); 

  }







  activatePremium(imei, uid) {

    console.log(this.validupto);
    const dt = this.datePipe.transform(this.validupto, ' MMM d, y, h:mm:ss a');
    const addedPremium = this.http.get('https://digitaltag.tag8.in/api/activateUser/' + imei + '&' + dt + '&' + uid, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    addedPremium.subscribe((respone) => {
      this.snackBar.open('Added Premium Member', 'Undo', {
        duration: 3000
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



}
