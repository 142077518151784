import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { Coupon } from './coupons.model';


const URL = 'https://digitaltag.tag8.in/api/uploadCoupons/';

export interface DialogData {
  coupons_inital: string;
  coupons_plan: string;
  coupons_amount: string;
  coupons_percent: string;
  coupons_quantity: string;
  coupons_validUpto: string;
}



@Component({
  selector: 'app-forms',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit, AfterViewInit {

  coupons_inital;
  coupons_percent;
  coupons_amount;
  coupons_plan;
  coupons_quantity;
  coupons_status;
  coupons_validUpto = new Date();
  percent = false;
  amount = false;
  current_date = new Date();
  discount_amount;
  fileToUpload: File = null;


  dispalyedColumns = ['coupon_id', 'coupons_inital', 'coupons_percent'
    , 'coupons_amount', 'discount_amount', 'plan_type', 'coupons_status', 'coupons_createdOn', 'coupons_validUpto'];
  dataSource = new MatTableDataSource<Coupon>();
  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });


  constructor(public dialog: MatDialog, public http: HttpClient) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddCouponsDialog, {
      width: '450px',
      data: {
        coupons_inital: this.coupons_inital,
        coupons_percent: this.coupons_percent,
        coupons_amount: this.coupons_amount, coupon_plan: this.coupons_plan
        , coupon_quanity: this.coupons_quantity, coupons_validUpto: this.coupons_validUpto
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit() {
    const coupon = this.http.get('https://digitaltag.tag8.in/api/getCoupon', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    coupon.subscribe((response: any) => {
      console.log(response);
      this.dataSource.data = response;
    });

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any) => {
      console.log('ImageUpload:uploaded:', item, response);
      location.reload();
    };


  }


  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadCsv();
  }



  uploadCsv() {
    console.log('Method Called');
    console.log(this.fileToUpload);
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.http.post('https://digitaltag.tag8.in/api/uploadCoupons/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
    });

  }


  ngAfterViewInit() {
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'addcoupons.html',
  styleUrls: ['./addcoupons.scss']
})
export class AddCouponsDialog {

  coupon_codes = [];
  coupons_inital;
  coupons_percent;
  coupons_amount;
  coupons_plan;
  coupons_quantity;
  coupons_status;
  coupons_validUpto = new Date();
  percent = false;
  amount = false;
  current_date = new Date();
  discount_amount;

  constructor(
    public dialogRef: MatDialogRef<AddCouponsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public http: HttpClient,
    public snackBar: MatSnackBar) { }

  displayAmount() {
    this.percent = true;
    this.amount = false;

  }
  displayPercent() {
    this.percent = false;
    this.amount = true;
  }



  addCoupon() {
    // console.log(this.coupons_quantity);
    console.log(this.current_date);
    console.log(this.coupons_validUpto);

    const data = {
      coupons_inital: this.coupons_inital,
      plan_type: this.coupons_plan,
      coupons_percent: this.coupons_percent,
      coupons_quantity: this.coupons_quantity,
      coupons_amount: this.coupons_amount,
      coupons_createdOn: this.current_date,
      coupons_validUpto: this.coupons_validUpto,

    };

    this.http.post('https://digitaltag.tag8.in/api/addCoupon/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
      window.setTimeout(
        () => {
          const body = {
            coupons_inital: this.coupons_inital,
            coupons_createdOn: this.current_date
          };
          this.http.get('https://digitaltag.tag8.in/api/couponMail/' +this.coupons_inital + '&' +this.current_date , {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
          }).subscribe((re1: any) => {
            this.snackBar.open(' Coupons Added Mail Deilvered  ', 'Undo', {
              duration: 3000
            });

            console.log(re1);
            location.reload();

          });
        }
        , 3000);
    });



  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
