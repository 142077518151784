import { Component, OnInit , ViewChild , AfterViewInit  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../core/auth.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { MatTableDataSource , MatSort , MatPaginator } from '@angular/material';
import { UseriddetailsService } from '../useriddetails.service';
import {MatSnackBar} from '@angular/material';
import { Phones } from './phone.model';
import { Passport } from './passport.model';
import { Bags } from './bag.model';
import { Key } from './key.model';


@Component({
  selector: 'app-itemlist',
  templateUrl: './itemlist.component.html',
  styleUrls: ['./itemlist.component.css']
})
export class ItemlistComponent implements OnInit  , AfterViewInit   {

  dispalyedCol1 = [ 'imei', 'name' , 'geolocation', 'brand' , 'model'  , 'status', 'premium'];
  datasource1 = new  MatTableDataSource<Phones>();

  @ViewChild('table1', {read: MatSort}) firstDataSort: MatSort;
  @ViewChild('page1', {read: MatPaginator}) firstDataPaginator: MatPaginator;


  dispalyedCol2 = [ 'uid', 'passport_id', 'expiry_date' , 'status' , 'createdOn'];
  datasource2 = new  MatTableDataSource<Passport>();

  @ViewChild('table2', {read: MatSort}) secondDataSort: MatSort;
  @ViewChild('page2', {read: MatPaginator}) secondDataPaginator: MatPaginator;


  dispalyedCol3 = [ 'uid', 'brand', 'color' , 'status' , 'createdOn'];
  datasource3 = new  MatTableDataSource<Bags>();

  @ViewChild('table3', {read: MatSort}) thirdDataSort: MatSort;
  @ViewChild('page3', {read: MatPaginator}) thirdDataPaginator: MatPaginator;


  dispalyedCol4 = [ 'uid', 'type' , 'status' , 'createdOn'];
  datasource4  = new  MatTableDataSource<Key>();

  @ViewChild('table4', {read: MatSort}) fourDataSort: MatSort;
  @ViewChild('page4', {read: MatPaginator}) fourDataPaginator: MatPaginator;

  constructor(  public  http: HttpClient , public uidService: UseriddetailsService
     , public authService: AuthService ,
      private location: Location , public snackBar: MatSnackBar , private datePipe: DatePipe ) { }
  data;
  passport;
  bag;
  key;

  ngOnInit() {

    const phone = this.http.get('https://digitaltag.tag8.in/api/phoneDetails' , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
    });
    phone.subscribe((response: any) => {
        console.log(response);
        this.datasource1.data = response;
     }
    );
    const  passport = this.http.get('https://digitaltag.tag8.in/api/passport' , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
    });
    passport.subscribe((response: any) => {
        console.log(response);
      this.datasource2.data = response;
     }
    );
    const  bag = this.http.get('https://digitaltag.tag8.in/api/bag' , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
  });
    bag.subscribe((response: any) => {
      console.log(response);
        this.datasource3.data = response;

    }
    );
    const key = this.http.get('https://digitaltag.tag8.in/api/key' , {
      headers: {'Authorization': 'Bearer ' + sessionStorage.getItem('token')}
  });
    key.subscribe((response: any) => {
      console.log(response);
      this.datasource4.data = response;

     }
    );
}

activatePremium(imei , vd  , uid) {


  console.log(this.datePipe.transform(vd.value, 'MMM d, y, h:mm:ss a' ) );
  const dt = this.datePipe.transform(vd.value, 'MMM d, y, h:mm:ss a' );
  const  addedPremium = this.http.get('https://digitaltag.tag8.in/api/activateUser/' +  imei + '&' + dt + '&' + uid , {
    headers: {'Authorization': 'Bearer ' + this.uidService.token}
});
  addedPremium.subscribe( (respone) => {
    this.snackBar.open('Added Premium Member', 'Undo', {
      duration: 3000
    });
  });


}


doFilter1(filterValue: string) {
  this.datasource1.filter = filterValue.trim().toLowerCase( );
}

doFilter2(filterValue: string) {
  this.datasource2.filter = filterValue.trim().toLowerCase( );
}

doFilter3(filterValue: string) {
  this.datasource3.filter = filterValue.trim().toLowerCase( );
}

doFilter4(filterValue: string) {
  this.datasource4.filter = filterValue.trim().toLowerCase( );
}

ngAfterViewInit() {
  this.datasource1.sort = this.firstDataSort;
  this.datasource1.paginator  = this.firstDataPaginator;
  this.datasource2.sort = this.secondDataSort;
  this.datasource2.paginator  = this.secondDataPaginator;
  this.datasource3.sort = this.thirdDataSort;
  this.datasource3.paginator  = this.thirdDataPaginator;
  this.datasource4.sort = this.fourDataSort;
  this.datasource4.paginator  = this.fourDataPaginator;
}
logout() {
  this.authService.doLogout()
  .then((res) => {
    sessionStorage.removeItem('token');
    this.location.back();
  }, (error) => {
    console.log('Logout error', error);
  });
}

}
