import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../../core/auth.service'
import { UserService } from '../../../core/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Router, Params } from '@angular/router';
import { Chart } from 'chart.js';
import * as moment from 'moment';

export interface DialogData1 {
  email: string;
  uid: string,
  password: string;
  type: string;
}

@Component({
  selector: 'app-forms',
  templateUrl: './UserProfile.component.html',
  styleUrls: ['./UserProfile.component.scss']
})
export class UserProfileComponent implements OnInit {
  chart: any;
  userid: any;
  firstname: any;
  lastname: any;
  address: any;
  phone: any;
  userAssets: any;
  totalDays: any;
  leftDays: any;
  startDate: any;
  endDate: any;
  pending: any;
  savednetworks = [];
  wifiData = [];
  user_status: any;
  email: any;
  uid: any;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    public http: HttpClient,
  ) { }

  ngOnInit() {

    this.userService.getCurrentUser().then((res) => {
      const email = res.providerData[0].email;
      this.http.get('https://digitaltag.tag8.in/api/getUsersByEmail/' + email, {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
      }).subscribe((response: any) => {
        this.userid = response.data.uid;
        this.firstname = response.data.firstname;
        this.lastname = response.data.lastname;
        this.address = response.data.address;
        this.phone = response.data.phone;
        console.log(this.userid);
        this.http.get('https://digitaltag.tag8.in/api/getDetails/' + this.userid, {
          headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
        }).subscribe((re: any) => {
          this.userAssets = re.data;

          this.http.get('https://digitaltag.tag8.in/api/getdevicewifi/' + this.userid + '&' + this.userAssets[0].device_id, {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('usertoken') }
          }).subscribe((res: any) => {
            this.wifiData = res.data;

          });

        });
      });
    });
  }

  getProfileView(usersStatus, startDate, endDate) {

    if (usersStatus == 'FREE') {

      this.user_status = 'FREE';

      console.log('FREE USER');

    } else if (usersStatus == 'PAID') {

      this.user_status = 'PAID';
      console.log('PAID USER');
      console.log(startDate);
      console.log(endDate);
      this.startDate = moment(startDate);
      this.endDate = moment(endDate);
      this.totalDays = this.endDate.diff(this.startDate, 'days');
      let currentDate = moment();
      this.leftDays = this.endDate.diff(currentDate, 'days');
      this.pending = parseInt(this.totalDays) - parseInt(this.leftDays);
      setTimeout(() => {
        this.generateChart(this.leftDays, this.pending);
      }, 1000);
    }


  }

  generateChart(days1, days2) {
    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: ['Days Left', 'Days Completed'],
        datasets: [
          {
            data: [parseInt(days1), parseInt(days2)],
            backgroundColor: ["#fb8c00", "#1f1f1f"],
            fill: false,
            borderWidth: 1,
          },
        ]
      },
      options: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: "#000000"
          }
        },
        cutoutPercentage: 65,
        responsive: true,
        tooltips: {
          enabled: true,
          backgroundColor: '#000000',
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        /*  rotation: 1 * Math.PI,
            circumference: 1 * Math.PI, */
      }
    });
  }

  tabClick(tab) {
    // for(let i=0; i<this.userAssets.length; i++)
    // {

    console.log(tab.index);

    // if (tab.index === i ) {

    this.getProfileView(this.userAssets[tab.index].subscription_plan, this.userAssets[tab.index].membership_date, this.userAssets[tab.index].membership_expiry_date);
    // } else {

    //  console.log(this.userAssets[i].model);   

    //  this.getProfileView(this.userAssets[i].subscription_plan,this.userAssets[i].membership_date ,this.userAssets[i].membership_expiry_date);
    // }




    //}
  }

  openDialog1(dat): void {
    console.log(dat);
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '350px',
      data: {
        uid: dat
      }
    });
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'edituser.html',
  styleUrls: ['./edituser.css']
})
export class EditUserDialog {

  hide = true;
  uid: any;
  email: any;
  password: any;
  firstname: any;
  lastname: any;
  phone: any;
  address: any;
  selected: 'CCuser';
  current_date = new Date();

  constructor(
    public dialogRef: MatDialogRef<EditUserDialog>,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData1) { }


  editUser() {
    const data = {
      uid: this.data.uid,
      phone: this.phone,
      firstname: this.firstname,
      lastname: this.lastname,
      address: this.address,
    };
    this.http.post('https://digitaltag.tag8.in/api/updateuserName/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
    });
    this.http.post('https://digitaltag.tag8.in/api/updateuserAddress/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
    });
    this.http.post('https://digitaltag.tag8.in/api/updateuserPhone/', data, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    }).subscribe((response) => {
      console.log(response);
      this.snackBar.open(' User Updated  ', 'Undo', {
        duration: 3000
      });
      location.reload();
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
