import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../core/auth.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { Phones } from './phone.model';
var start = 0;

@Component({
  selector: 'app-tables',
  templateUrl: './Itemslist.component.html',
  styleUrls: ['./Itemslist.component.scss']
})
export class ItemListComponent implements OnInit, AfterViewInit {

  dispalyedCol1 = [ 'name', 'geolocation', 'brand', 'model', 'status' , 'createdon'];
  datasource1 = new MatTableDataSource<Phones>();
 
  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;
  data1;
  start;

  constructor(public http: HttpClient
    , public authService: AuthService,
    private location: Location, public snackBar: MatSnackBar, private datePipe: DatePipe) { }

  ngOnInit() {
    var body = {
        
    }
    const phone = this.http.post('https://digitaltag.tag8.in/api/batchDetails', body, {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });
    phone.subscribe((response: any) => {
      console.log(response);
      this.datasource1.data = response.data;
    }
    );
  }


  onPaginateChange() {

    start += 100;


    var body = {
      start:start
    }
    const obs = this.http.post('https://digitaltag.tag8.in/api/batchDetails', body , {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

        console.log(start);

    obs.subscribe((response: any) => {
       this.data1 = this.datasource1.data;   

        for(var i=0;i<response.data.length;i++) {
          this.data1.push(response.data[i]);
        }
        console.log(this.data1);
        this.datasource1.data = this.data1;
    });
  }

  doFilter1(filterValue: string,search) {
    //this.datasource1.filter = filterValue.trim().toLowerCase();

    console.log(search);
      var body = {
        search :search
      }
    
    const obs = this.http.post('https://digitaltag.tag8.in/api/batchDetails' , body ,
     {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {

      this.datasource1.data = response.data;

    });


  }

  ngAfterViewInit() {
    this.datasource1.sort = this.firstDataSort;
    this.datasource1.paginator  = this.firstDataPaginator;
  }
}
