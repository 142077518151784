import { Component, OnInit , ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient  } from '@angular/common/http';
import { Inactive } from './inactive.model';

@Component({
  selector: 'app-inactiveusers',
  templateUrl: './inactiveusers.component.html',
  styleUrls: ['./inactiveusers.component.scss']
})
export class InactiveusersComponent implements OnInit {


  dispalyedColumns = ['firstname', 'email', 'registredon',];


  dataSource = new MatTableDataSource<Inactive>();



  @ViewChild('table1', { read: MatSort }) firstDataSort: MatSort;
  @ViewChild('page1', { read: MatPaginator }) firstDataPaginator: MatPaginator;


  constructor(
    public http: HttpClient,
  ) { }

  ngOnInit() {

    const obs = this.http.get('https://digitaltag.tag8.in/api/getinactiveusers', {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    });

    obs.subscribe((response: any) => {

      console.log(response);

      this.dataSource.data = response;


    });


  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngAfterViewInit() {
    
    this.dataSource.sort = this.firstDataSort;
    this.dataSource.paginator = this.firstDataPaginator;


  }


}
