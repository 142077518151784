import { Component, OnInit } from '@angular/core';
import { NguCarousel } from '@ngu/carousel';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.css']
})
export class SlideshowComponent implements OnInit {

  public carouselOptions: NguCarousel;
  public portfolios = [{
    photo: 'assets/images/14.jpg',
  }, {
    photo: 'assets/images/13.jpg',
  }, {
    photo: 'assets/images/15.jpeg',
  }, {
    photo: 'assets/images/16.jpg',
  }, {
    photo: 'assets/images/11.jpg',
  }, {
    photo: 'assets/images/12.jpg',
  }]

  constructor() { }

  ngOnInit() {
    this.carouselOptions = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
      slide: 2,
      speed: 1200,
      interval: 2000,
      point: {
        visible: true
      },
      load: 2,
      touch: true,
      loop: true
    }
  }

}
