import { Component , OnInit  } from '@angular/core';
import { AuthService } from '../core/auth.service';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  myStyle: object = {};
  myParams: object = {};
  width = 100;
  height = 100;
  

  loginForm: FormGroup;
  errorMessage = '';

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    public  http: HttpClient,
    public snackBar: MatSnackBar,
    private _firebaseAuth: AngularFireAuth
  ) {
    this.createForm();
  }
  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['', Validators.required]
    });
  }


  tryLogin(value) {
    console.log(value);
    const body =  { email: value.email , password: value.password  };
       this.http.post('https://digitaltag.tag8.in/api/auth/login' , body).subscribe((response: any) => {
          //  console.log(response.hasOwnProperty('token'));
        if (response.hasOwnProperty('token')) { 
          this.snackBar.open('Login', 'OK', {
            duration: 3000
          });
          if (response.type === 'admin') {
                this.authService.doLogin(value)
         .then(res => {
          sessionStorage.setItem('token', response.token);
        this.router.navigate(['/UserAdmin']);
        }, err => {
        console.log(err);
        this.errorMessage = err.message;
         });
          } else if (response.type === 'ccuser') {

             sessionStorage.setItem('token', response.token);
           this.router.navigate(['/CCuser']);


          }else if (response.type === 'maruser') {

            sessionStorage.setItem('token', response.token);
          this.router.navigate(['/addnotifications']);


         }

        } else if(response.type === 'Invalid') {
          this.snackBar.open('Invalid Username or Password', 'Error', {
            duration: 3000
          });
        }


     });
  }


  ngOnInit() {
      this.myStyle = {
          'position': 'fixed',
          'width': '100%',
          'height': '100%',
          'z-index': -1,
          'top': 0,
          'left': 0,
          'right': 0,
          'bottom': 0,
          'background-color': '#2E3F59',
      };

      this.myParams = {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800
            }
          },
            color: {
                value: '#FFFAFA'
            },
            shape: {
              type: 'circle',
              polygon: {
                nb_sides: 5
              }
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#ffffff',
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 6,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: true,
          mode: 'grab'
        },
        onclick: {
          enable: true,
          mode: 'push'
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 140,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3
        },
        repulse: {
          distance: 200,
          duration: 0.4
        },
        push: {
          particles_nb: 4
        },
        remove: {
          particles_nb: 2
        }
      }
    },
    retina_detect: true,
  };

}

}
