import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rootRouterConfig } from './app.routes';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ParticlesModule } from 'angular-particle';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
import { DataTableModule } from 'angular-6-datatable';
import { DataTablesModule } from 'angular-datatables';
import { MapviewComponent } from './mapview/mapview.component';
import { LocationmapComponent } from './locationmap/locationmap.component';

import { ItemlistComponent } from './itemlist/itemlist.component';

import { AddnotificationsComponent } from './addnotifications/addnotifications.component';
import { FileModule } from './file.module';
import { MaterialModule } from './material.module';

import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { LightboxModule } from 'ngx-lightbox';
import { CouponComponent } from './coupon/coupon.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DialogOverviewExampleDialog } from './coupon/coupon.component';
import { DialogOverviewDialog } from './user-list/user-list.component';


import { DatePipe } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { CchomeComponent } from './cchome/cchome.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { MatVideoModule } from 'mat-video';
import { LocationComponent } from './location/location.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FeaturesComponent } from './home/features/features.component';
import { NguCarouselModule } from '@ngu/carousel';
import { MatCardModule } from '@angular/material/card';
import { SlideshowComponent } from './home/slideshow/slideshow.component';
import { TestimonialsComponent } from './home/testimonials/testimonials.component';
import { PricingsComponent } from './home/pricings/pricings.component';
import { BuynowComponent } from './home/buynow/buynow.component';
import { FooterComponent } from './home/footer/footer.component';
import { AboutComponent } from './home/about/about.component';
import { MobileViewPoliceCompComponent } from './mobile-view-police-comp/mobile-view-police-comp.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MobileViewAboutUsComponent } from './mobile-view-about-us/mobile-view-about-us.component';
import { FaqComponent } from './faq/faq.component';
import { MatTableModule } from '@angular/material/table';

import { UserPreModule } from './user-premium/UserPre.module'; // Import User Premium Module
import { UserAdmModule } from './user-admin/UserAdm.module'; // Import User Admin Module
import { UserAdminViewModule } from './user-admin-view/UserAdminView.module'; // Import User Admin view Module
import { CCuserModule } from './customer_care/ccuser.module'; // Import CCuser Module
import { UserFreeModule } from './user-free/UserFree.module';
import { ReportfoundComponent } from './reportfound/reportfound.component';
import { InactiveusersComponent } from './inactiveusers/inactiveusers.component'; // Import User Free Module

import { BlogPageComponent } from './blog-page/blog-page.component';

import { Blog1Component } from './blog-page/blog1/blog1.component';
import { Blog2Component } from './blog-page/blog2/blog2.component';
import { UserguideComponent } from './userguide/userguide.component';
import { Blog3Component } from './blog-page/blog3/blog3.component';
import { Blog4Component } from './blog-page/blog4/blog4.component';




//Contains all the Component and Module Intergrates the entire UserAdmModule having the code of Admin Panel,
// UserPreModule contains the Code for the premium User Module,
// UserAdminViewModule contains code for the User Panel from the Admin Dashboard,
// UserFreeModule contains code for the free User Panel
//)

@NgModule({
  declarations: [

    AppComponent,
    LoginComponent,
    DialogOverviewDialog,
    MapviewComponent,
    LocationmapComponent,

    ItemlistComponent,
    AddnotificationsComponent,
    AdminComponent,
    HomeComponent,
    CouponComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    DialogOverviewExampleDialog,
    UserListComponent,
    CchomeComponent,
    LocationComponent,
    FeaturesComponent,
    SlideshowComponent,
    TestimonialsComponent,
    PricingsComponent,
    BuynowComponent,
    FooterComponent,
    AboutComponent,
    MobileViewPoliceCompComponent,
    MobileViewAboutUsComponent,
    FaqComponent,
    ReportfoundComponent,
    InactiveusersComponent,
    BlogPageComponent,
    Blog1Component,
    Blog2Component,
    UserguideComponent,
    Blog3Component,
    Blog4Component

  ],
  entryComponents: [DialogOverviewExampleDialog, DialogOverviewDialog],
  imports: [
    NgxSpinnerModule,
    MatVideoModule,
    BrowserModule,
    FileModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBxzri4-_Mg7d7X1W5wodq6SPnxWSGdNBQ'
    }),
    RouterModule.forRoot(rootRouterConfig, { useHash: true }),
    ScrollToModule.forRoot(),

    AngularFireModule.initializeApp(environment.firebase),

    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    DataTableModule,
    DataTablesModule,
    ParticlesModule,
    SlideshowModule,
    LightboxModule,
    MatCheckboxModule,
    MDBBootstrapModule.forRoot(),
    NguCarouselModule,
    MatCardModule,
    UserPreModule, // User Premium Module call
    UserAdmModule, // User Admin Module call
    UserAdminViewModule,
    CCuserModule, // CCuser Free Module call
    UserFreeModule,  // User Free Module call
    MatTableModule
  ],
  providers: [AuthService, UserService, AuthGuard, DatePipe],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {
}