import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from '../app.routes';  // Main Routes Import
import { RouterModule } from '@angular/router';


//Material Module start
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';


import { UserAdminViewComponent } from './user-admin-view.component'; 
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { UADashboardComponent } from './pages/dashboard/dashboard.component';
import { UAFileComplaintComponent } from './pages/FileComplaint/FileComplaint.component';
import { UAUserProfileComponent } from './pages/UserProfile/UserProfile.component';
import { UAMapsComponent } from './pages/maps/maps.component';
import { TimeagoModule } from 'ngx-timeago';
import { warningwipedataDialog } from './pages/dashboard/dashboard.component';
import {
  AgmCoreModule
} from '@agm/core';

@NgModule({
  declarations: [
    UserAdminViewComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    FullscreenComponent,
    UADashboardComponent,
    UAFileComplaintComponent,
    UAUserProfileComponent,
    UAMapsComponent,

    warningwipedataDialog
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: true }),  // Main Routes call
    CommonModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    ToastrModule.forRoot(),
    TimeagoModule.forRoot(),
    MatButtonModule,
    MDBBootstrapModule.forRoot(),
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
    
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'AIzaSyBxzri4-_Mg7d7X1W5wodq6SPnxWSGdNBQ'
    }),
    ],
  providers: [],
  entryComponents: [warningwipedataDialog],
  bootstrap: [UserAdminViewComponent]
})
export class UserAdminViewModule { }
